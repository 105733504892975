import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { IStructuredData } from "@ax/types";
import { deepClone } from "@ax/helpers";
import { MenuItem, SubNav } from "@ax/components";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { structuredDataActions } from "@ax/containers/StructuredData";

import { filterStructure } from "./constants";
import { getFilters } from "./utils";

import * as S from "./style";

const ContentFilters = (props: IProps): JSX.Element => {
  const { setFilter, current, dynamicValues, onClick, addNew, isAllowedToCreate } = props;
  const clonedStructure = deepClone(filterStructure);
  const filters = getFilters(clonedStructure, dynamicValues);

  return (
    <SubNav>
      {filters.map((category: any, key: number) => {
        return (
          <S.Item key={key}>
            <S.Heading>{category.title}</S.Heading>
            {category.filters &&
              category.filters.map((filter: any, filterKey: number) => {
                const { name, value, editable } = filter;

                const handleClick = () => {
                  onClick(value);
                  setFilter(value);
                };

                const isSelected = value === current;
                const selectedClass = isSelected ? "selected" : "";

                const extendedAction = {
                  icon: "add",
                  action: addNew,
                  onlyOnHover: false,
                };

                return (
                  <MenuItem
                    key={filterKey}
                    onClick={handleClick}
                    extendedAction={isSelected && editable && isAllowedToCreate ? extendedAction : null}
                  >
                    <NavLink to="#" className={selectedClass}>
                      <S.Link active={isSelected}>{name}</S.Link>
                    </NavLink>
                  </MenuItem>
                );
              })}
          </S.Item>
        );
      })}
    </SubNav>
  );
};

interface IProps {
  current: string | undefined;
  setFilter(filter: string | null): void;
  addTemplate(template: string): void;
  dynamicValues: IStructuredData[];
  onClick(dataID: string): void;
  addNew: () => void;
  isAllowedToCreate: boolean;
}

const mapDispatchToProps = {
  addTemplate: pageEditorActions.addTemplate,
  setFilter: structuredDataActions.setFilter,
};

export default connect(null, mapDispatchToProps)(ContentFilters);
