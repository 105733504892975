import styled from "styled-components";

const Wrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  display: ${(p) => (p.isVisible ? "block" : "none")};
  transition: all 0.5s;
  &.error {
    background-color: ${(p) => p.theme.colors.error};
  }
  &.info {
    background-color: ${(p) => p.theme.colors.info};
  }
  &.success {
    background-color: ${(p) => p.theme.colors.online};
  }
  &.warning {
    background-color: ${(p) => p.theme.colors.warning};
  }
`;

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.spacing.s};
  ${(p) => p.theme.spacing.m};
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  ${(p) => p.theme.textStyle.uiL};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 2;

  svg {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
    path {
      fill: ${(p) => p.theme.color.textHighEmphasisInverse};
    }
  }
`;

const IconWrapper = styled.span`
  padding-right: ${(p) => p.theme.spacing.s};
  height: 24px;
`;

const Text = styled.span``;

const ActionsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const CloseWrapper = styled.span`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const ActionBtn = styled.button<{ actionsBelow?: boolean }>`
  ${(p) => p.theme.textStyle.uiButton};
  width: fit-content;
  padding: ${(p) => `0 ${p.theme.spacing.s}`};
  background: none;
  outline: none;
  border: 1px solid ${(p) => p.theme.colors.uiBackground02};
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  height: 32px;
  margin-right: ${(p) => (p.actionsBelow ? 0 : p.theme.spacing.s)};
  margin-bottom: ${(p) => (p.actionsBelow ? p.theme.spacing.s : 0)};
`;

const Button = styled.div<{ isOpen: boolean }>`
  position: relative;
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.colors.textHighEmphasisInverse};
  padding-right: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.m};
  cursor: pointer;
  :after {
    position: absolute;
    right: 0;
    top: ${(p) => (p.isOpen ? `6px` : `2px`)};
    content: "";
    border: solid ${(p) => p.theme.color.interactiveInverse};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: ${(p) => (p.isOpen ? `rotate(-135deg)` : `rotate(45deg)`)};
  }
`;

export { Wrapper, NotificationWrapper, Text, IconWrapper, Row, CloseWrapper, ActionBtn, ActionsWrapper, Button };
