import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import { IDataSource, IStructuredDataContent } from "@ax/types";
import { getFormattedDateWithTimezone } from "@ax/helpers";

import { Icon } from "@ax/components";

import * as S from "./style";

const Item = (props: IProps) => {
  const { item, handleDelete, listLength, source, innerRef, provided } = props;

  const removeItem = () => {
    handleDelete(item);
  };

  const menuOptions = [
    {
      label: "delete",
      icon: "delete",
      action: removeItem,
    },
  ];

  return (
    <S.Item ref={innerRef} data-testid="reference-field-item" {...provided?.draggableProps}>
      <S.HandleWrapper {...provided?.dragHandleProps} hidden={listLength < 2} data-testid="handle-wrapper">
        <S.IconHandleWrapper>
          <Icon name="drag" size="16" />
        </S.IconHandleWrapper>
      </S.HandleWrapper>
      <S.TextWrapper>
        <S.Header>
          <S.Type>{source?.title.toUpperCase()}</S.Type>
          {item.modified && <S.Date>{getFormattedDateWithTimezone(item.modified, "d MMM Y")}</S.Date>}
        </S.Header>
        <S.Title>{item.content.title}</S.Title>
      </S.TextWrapper>
      <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />
    </S.Item>
  );
};

interface IProps {
  item: IStructuredDataContent;
  listLength: number;
  source: IDataSource;
  handleDelete(item: IStructuredDataContent): void;
  innerRef: any;
  provided: DraggableProvided;
}

export default Item;
