import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { IRootState, IUser, ISite, IGetRoles, IRole, IGetSitesParams, IQueryValue } from "@ax/types";
import { useBulkSelection, useModal, usePermission, useToast } from "@ax/hooks";
import { appActions } from "@ax/containers/App";
import { usersActions } from "@ax/containers/Users";
import { sitesActions } from "@ax/containers/Sites";

import {
  MainWrapper,
  TableList,
  ErrorToast,
  EmptyState,
  Modal,
  Nav,
  Toast,
  SearchTagsBar,
  FilterTagsBar,
} from "@ax/components";

import BulkHeader from "./BulkHeader";
import UserItem from "./UserItem";
import { useFilterQuery, useSortedListStatus } from "./hooks";
import { getSortedListStatus } from "./utils";

import * as S from "./style";

const UserList = (props: IUserListProps): JSX.Element => {
  const {
    currentSiteInfo,
    users,
    getUsers,
    navItems,
    currentNavItem,
    setHistoryPush,
    getUser,
    resetUserData,
    sites,
    deleteUser,
    removeUsersBulk,
    getRoles,
    roles,
    getSites,
  } = props;

  const itemsPerPage = 50;
  const firstPage = 1;

  const [page, setPage] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [usersDeleted, setUSersDeleted] = useState(1);

  const { sortedListStatus, setSortedListStatus } = useSortedListStatus();
  const { setFiltersSelection, resetFilterQuery, filterValues, filterQuery } = useFilterQuery();
  const { isVisible, toggleToast, setIsVisible } = useToast();

  const tableRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isSiteView = !!currentSiteInfo;

  const { isOpen, toggleModal } = useModal();

  const allowedToCreateUsers = usePermission("usersRoles.createUsers");

  const usersIds = users.map((user: IUser) => user.id);

  const {
    resetBulkSelection,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
    selectedItems,
  } = useBulkSelection(usersIds);

  useEffect(() => {
    const handleGetUsers = async () => {
      const query = searchQuery ? (filterQuery ? `&query=${searchQuery}` : `?query=${searchQuery}`) : "";
      const currentFilterQuerySite =
        isSiteView && filterQuery ? `${filterQuery}&site=${currentSiteInfo.id}` : filterQuery;
      await getUsers({ filterQuery: currentFilterQuerySite, query }, currentSiteInfo?.id);
    };
    handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery, searchQuery]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    const handleRolesSites = async () => {
      const siteId = isSiteView ? currentSiteInfo.id : "global";
      await getRoles({ siteId }, undefined, false);
      await getSites({ pagination: false, recentSitesNumber: 0 });
    };
    handleRolesSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = (path: string) => {
    setHistoryPush(path);
  };

  const unselectAllItems = () => resetBulkSelection();

  const selectItems = () => (checkState.isAllSelected ? unselectAllItems() : handleSelectAll());

  const handleSelectAll = () => selectAllItems();

  const onScroll = (e: any) => setIsScrolling(e.target.scrollTop > 0);

  const totalItems = users.length;

  const firstPageUser = (page - 1) * itemsPerPage;
  const pageUsers = users.slice(firstPageUser, firstPageUser + itemsPerPage);
  const isEmpty = !users || users.length === 0;
  const BASE_URL = isSiteView ? "/sites/users" : "/users";
  const pagination = {
    setPage,
    itemsPerPage,
    totalItems,
    currPage: page,
  };

  const bulkDelete = () => {
    if (isSiteView) {
      const siteId = currentSiteInfo.id;
      removeUsersBulk(siteId, selectedItems.all);
    } else {
      deleteUser(selectedItems.all);
    }
    setUSersDeleted(selectedItems.all.length);
    toggleModal();
    toggleToast();
  };

  const rightButtonProps = allowedToCreateUsers
    ? {
        label: "New",
        action: () => setHistoryPush(`${BASE_URL}/new`),
      }
    : undefined;

  const sortItems = async (orderPointer: IQueryValue[], isAscending: boolean) => {
    setPage(firstPage);
    const sortedState = getSortedListStatus(orderPointer[0].value.toString(), isAscending);
    setSortedListStatus(sortedState);
    setFiltersSelection("order", orderPointer, isAscending);
  };

  const filterItems = async (filterPointer: string, filtersSelected: IQueryValue[]) => {
    setPage(firstPage);
    setFiltersSelection(filterPointer, filtersSelected);
  };

  const handleClick = (id: number) => async () => {
    resetUserData();
    await getUser(id);
    setHistoryPush(`${BASE_URL}/edit`);
  };

  const TableHeader = (
    <BulkHeader
      isSiteView={isSiteView}
      filterValues={filterValues}
      showBulk={areItemsSelected(usersIds)}
      bulkDelete={toggleModal}
      selectAllItems={handleSelectAll}
      totalItems={totalItems}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      sortItems={sortItems}
      filterItems={filterItems}
      sortedListStatus={sortedListStatus}
      roles={roles}
    />
  );

  const suffix = usersDeleted > 1 ? "s" : "";

  const toastProps = {
    setIsVisible,
    message: isSiteView ? `${usersDeleted} User${suffix} removed from site` : `${usersDeleted} User${suffix} deleted`,
  };

  const handleToggleToast = () => {
    setUSersDeleted(1);
    toggleToast();
  };

  const mainDeleteAction = { title: isSiteView ? "Remove from this site" : "Delete Users", onClick: bulkDelete };
  const secondaryDeleteAction = { title: "Cancel", onClick: toggleModal };

  const filterLabels = {
    filterRoles: "Role",
    filterSites: "Site",
  };

  return (
    <MainWrapper
      title="Users & Roles"
      rightButton={rightButtonProps}
      searchAction={setSearchQuery}
      searchValue={searchQuery}
    >
      <S.UsersWrapper ref={wrapperRef} data-testid="users-wrapper">
        <Nav current={currentNavItem} items={navItems} onClick={handleMenuClick} />
        <S.TableListWrapper>
          <ErrorToast />
          <TableList
            tableHeader={TableHeader}
            pagination={pagination}
            onScroll={onScroll}
            hasFixedHeader={true}
            tableRef={tableRef}
          >
            <>
              <S.SearchTags>
                <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
                <FilterTagsBar
                  filters={filterValues}
                  setFilters={setFiltersSelection}
                  resetFilters={resetFilterQuery}
                  labels={filterLabels}
                />
              </S.SearchTags>
              {isEmpty ? (
                <S.EmptyWrapper data-testid="empty-wrapper">
                  <EmptyState message="No users found" />
                </S.EmptyWrapper>
              ) : (
                pageUsers &&
                pageUsers.map((user: any) => {
                  const isItemSelected = isSelected(user.id);
                  return (
                    <UserItem
                      siteId={currentSiteInfo?.id}
                      isSiteView={isSiteView}
                      key={user.id}
                      user={user}
                      sites={sites}
                      isSelected={isItemSelected}
                      onChange={addToBulkSelection}
                      onClick={handleClick(user.id)}
                      roles={roles}
                      toggleDeleteToast={handleToggleToast}
                    />
                  );
                })
              )}
            </>
          </TableList>
        </S.TableListWrapper>
      </S.UsersWrapper>
      <Modal
        isOpen={isOpen}
        hide={toggleModal}
        title={isSiteView ? "Remove users from this site?" : "Delete Users?"}
        secondaryAction={secondaryDeleteAction}
        mainAction={mainDeleteAction}
      >
        {isOpen ? (
          <S.ModalContent data-testid="user-delete-bulk-modal">
            <p>
              {isSiteView
                ? "Are you sure you want to remove these users from this site? If you remove them, these users will no longer have access to this site but they will still be able to log in. This action cannot be undone."
                : "Are you sure you want to delete these users? If you delete them, they no longer be able to log in."}
            </p>

            <p>
              This action <strong>cannot be undone</strong>.
            </p>
          </S.ModalContent>
        ) : null}
      </Modal>
      {isVisible && <Toast {...toastProps} />}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  users: state.users.users,
  currentSiteInfo: state.sites.currentSiteInfo,
  sites: state.sites.sites,
  roles: state.users.roles,
});

const mapDispatchToProps = {
  getUsers: usersActions.getUsers,
  getUser: usersActions.getUser,
  resetUserData: usersActions.resetUserData,
  setHistoryPush: appActions.setHistoryPush,
  deleteUser: usersActions.deleteUser,
  removeUsersBulk: sitesActions.removeUsersBulk,
  getRoles: usersActions.getRoles,
  getSites: sitesActions.getSites,
};

interface IDispatchProps {
  getUsers(params: any, siteID?: number | null): Promise<void>;
  getUser(id: number): Promise<void>;
  resetUserData(): void;
  setHistoryPush(route: string): void;
  deleteUser(id: number[]): Promise<boolean>;
  removeUsersBulk(siteId: number, users: number[]): void;
  getRoles(params: IGetRoles, token?: string, hasLoading?: boolean): Promise<void>;
  getSites(params: IGetSitesParams): Promise<void>;
}

interface IProps {
  navItems: any[];
  currentNavItem: any;
  users: IUser[];
  currentSiteInfo: ISite | null;
  sites: ISite[];
  roles: IRole[];
}

export type IUserListProps = IProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
