import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { IDataPack, IStructuredData } from "@ax/types";
import { Icon, MenuItem } from "@ax/components";

import * as S from "./style";

const NavItem = (props: IProps): JSX.Element => {
  const { current, categories, dataPack, onClick, isFirst } = props;

  const initialState = current
    ? categories.map((category: IStructuredData) => category.id).includes(current.id)
    : isFirst;

  const [isOpen, setIsOpen] = useState(initialState);

  const toggleOpen = () => setIsOpen(!isOpen);

  const icon = isOpen ? "UpArrow" : "DownArrow";

  return (
    <S.NavItemWrapper>
      <S.NavLink onClick={toggleOpen}>
        <S.Title>{dataPack.title}</S.Title>
        <S.Arrow>
          <Icon name={icon} />
        </S.Arrow>
      </S.NavLink>
      <S.Dropdown isOpen={isOpen}>
        {categories &&
          categories.map((category: IStructuredData) => {
            const isSelected = !!current && category.id === current.id;
            const selectedClass = isSelected ? "selected" : "";
            const handleClick = () => onClick(category.id);
            return (
              <MenuItem key={category.id} onClick={handleClick}>
                <NavLink to="#" className={selectedClass}>
                  <S.Link active={isSelected}>{category.title}</S.Link>
                </NavLink>
              </MenuItem>
            );
          })}
      </S.Dropdown>
    </S.NavItemWrapper>
  );
};

interface IProps {
  current: IStructuredData | null;
  categories: IStructuredData[];
  onClick(id: string): void;
  dataPack: IDataPack;
  isFirst: boolean;
}

export default NavItem;
