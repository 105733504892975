import React from "react";
import { IconAction } from "@ax/components";

import * as S from "./style";

const MenuItem = (props: IMenuItemProps): JSX.Element => {
  const { children, onClick, active = false, extendedAction } = props;

  const handleOnClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (onClick !== undefined) {
      e.preventDefault();
      onClick(e);
    }
  };

  const handleExtendedAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    extendedAction && extendedAction.action();
  };

  return (
    <S.SubItem onClick={handleOnClick} active={active} data-testid="menu-subitem">
      {children}
      {extendedAction && (
        <S.ExtendedAction onlyOnHover={extendedAction.onlyOnHover ?? true} data-testid="menu-extended-action">
          <IconAction icon={extendedAction.icon} size="s" onClick={handleExtendedAction} />
        </S.ExtendedAction>
      )}
    </S.SubItem>
  );
};

export interface IMenuItemProps {
  children: JSX.Element | string;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  extendedAction?: { icon: string; action: () => void; onlyOnHover?: boolean } | null;
}

export default MenuItem;
