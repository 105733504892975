import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import { useModal } from "@ax/hooks";
import { isEmptyContainer, getDisplayName, trimText } from "@ax/helpers";
import { CheckField, Icon, SideModal } from "@ax/components";
import { ICheck } from "@ax/types";
import EmptyContainer from "./EmptyContainer";
import { ArrayContainerButtons, ContainerButtons } from "./atoms";

import * as S from "./style";

const ComponentContainer = (props: IComponentContainerProps): JSX.Element => {
  const {
    goTo,
    objKey,
    editorID,
    text,
    moduleTitle,
    selectedContent,
    whiteList,
    isArray,
    value,
    actions,
    title,
    categories,
    disabled,
    canDuplicate,
    parentKey,
    theme,
    canReplace,
    actionReplace,
    arrayLength,
    innerRef,
    provided,
    isModule,
    isSelected,
    onChange,
    hasMenu = true,
    toggleToast,
    className,
    isMultiDragging = false,
    draggingCount = 0,
  } = props;

  const { addComponentAction, deleteModuleAction, duplicateModuleAction, copyModuleAction, replaceModuleAction } =
    actions || {};

  const { isOpen, toggleModal } = useModal();

  const whiteListFirstItem: any = whiteList && whiteList[0];
  const hasMultipleOptions: boolean | undefined = whiteList && whiteList.length > 1;

  const containerOptions: any =
    parentKey && objKey ? selectedContent[parentKey][objKey] : objKey && selectedContent[objKey];

  let containerText: string = text ? text : getDisplayName(whiteListFirstItem);
  let componentID: number = editorID ? editorID : containerOptions?.editorID;

  const containerInfo: any = !isArray && value && isEmptyContainer(value, hasMultipleOptions);
  const isEmpty: boolean = containerInfo?.isEmpty;

  const currentComponent: any = containerInfo?.containedComponent;

  if (currentComponent) {
    containerText = currentComponent.containerText;
    componentID = currentComponent.componentID;
  }

  const handleClick = () => {
    if (!disabled) {
      goTo(componentID);
    }
  };

  const handleCheckClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const removeItem = () => deleteModuleAction && deleteModuleAction([editorID], parentKey);

  const duplicateItem = () => parentKey && duplicateModuleAction && duplicateModuleAction([editorID], parentKey);

  const copyItem = () => {
    const isCopied = copyModuleAction && copyModuleAction([editorID]);
    isCopied && toggleToast && toggleToast("1 module copied to clipboard");
  };

  const copyOpt = {
    label: "copy",
    icon: "copy",
    action: copyItem,
  };

  const duplicateOpt = {
    label: "duplicate",
    icon: "duplicate",
    action: duplicateItem,
  };

  const deleteOpt = {
    label: "delete",
    icon: "delete",
    action: removeItem,
  };

  const replaceOpt = {
    label: "replace",
    icon: "change",
    action: () => actionReplace && actionReplace(),
  };

  const actionArrayMenuOptions = [
    ...(isModule ? [copyOpt] : []),
    ...(canDuplicate ? [duplicateOpt] : []),
    deleteOpt,
    ...(canReplace ? [replaceOpt] : []),
  ];

  const actionMenuOptions = [
    {
      label: "replace",
      icon: "change",
      action: toggleModal,
    },
  ];

  const actionMenuIcon = "more";

  const handleEmptyContainerClick = () => {
    if (addComponentAction) addComponentAction(value);
  };

  const handleOptionClick = (option: any) => {
    if (addComponentAction) addComponentAction(option);
  };

  const compoundKey = parentKey ? `${parentKey}.${objKey}` : objKey;
  const handleReplace = (option: any) =>
    compoundKey && replaceModuleAction && replaceModuleAction(option, selectedContent, compoundKey);

  const arrayContainerButtonsProps = {
    options: actionArrayMenuOptions,
    icon: actionMenuIcon,
  };

  const handleChange = (value: ICheck) => onChange && onChange(value);

  const textComponents = isMultiDragging ? (
    <S.LabelDrag>
      Dragging <span>{draggingCount}</span> modules
    </S.LabelDrag>
  ) : (
    <>
      <S.Label containerInfo={containerInfo} disabled={disabled}>
        {trimText(containerText, 25)}
      </S.Label>
      {moduleTitle && <S.Title disabled={disabled}>{trimText(moduleTitle, 25)}</S.Title>}
    </>
  );

  return isEmpty ? (
    <EmptyContainer
      whiteList={whiteList}
      categories={categories}
      hasMultipleOptions={hasMultipleOptions}
      goTo={handleEmptyContainerClick}
      handleAdd={handleOptionClick}
      componentOptions={containerOptions}
      title={title}
      theme={theme}
    />
  ) : (
    <>
      <S.Component
        disabled={disabled}
        className={`editorId-${editorID} ${className}`}
        onClick={handleClick}
        ref={innerRef}
        data-testid="component-container"
        {...provided?.draggableProps}
      >
        {isArray && provided && (
          <S.HandleWrapper {...provided?.dragHandleProps} hidden={arrayLength < 2} data-testid="handle-wrapper">
            <S.IconHandleWrapper>
              <Icon name="drag" size="16" />
            </S.IconHandleWrapper>
          </S.HandleWrapper>
        )}
        {isArray && !isMultiDragging && arrayLength > 1 && (
          <S.CheckWrapper onClick={handleCheckClick}>
            <CheckField name="check" value={editorID} checked={isSelected} onChange={handleChange} light={true} />
          </S.CheckWrapper>
        )}
        {containerInfo && !disabled && (
          <S.IconWrapper data-testid="icon-wrapper">
            <Icon name={containerText} />
          </S.IconWrapper>
        )}
        <S.Text>{textComponents}</S.Text>
        {isArray && !disabled && !hasMenu && <ArrayContainerButtons {...arrayContainerButtonsProps} />}
        {!isArray && !disabled && hasMultipleOptions && (
          <ContainerButtons options={actionMenuOptions} icon={actionMenuIcon} />
        )}
      </S.Component>
      {!isArray && hasMultipleOptions && (
        <SideModal
          optionsType={"components"}
          whiteList={whiteList}
          categories={categories}
          handleClick={handleReplace}
          toggleModal={toggleModal}
          isOpen={isOpen}
          componentOptions={containerOptions}
          current={currentComponent}
          theme={theme}
        />
      )}
    </>
  );
};

export interface IComponentContainerProps {
  text: string;
  editorID: number;
  whiteList: string[] | undefined;
  categories?: any[];
  goTo: any;
  isArray?: boolean | undefined;
  value?: any;
  title?: string;
  moduleTitle?: string;
  selectedContent?: any;
  objKey?: string;
  actions?: {
    addComponentAction: (componentType: any, key?: string) => void;
    deleteModuleAction: (editorID: number[], key?: string) => void;
    duplicateModuleAction: (editorID: number[], key?: string) => number;
    copyModuleAction: (editorID: number[]) => boolean | number;
    replaceModuleAction: (module: any, parent: any, objKey: string) => void;
  };
  disabled?: boolean;
  canDuplicate?: boolean;
  parentKey?: string;
  theme: string;
  canReplace?: boolean;
  actionReplace?: () => void;
  arrayLength: number;
  innerRef?: any;
  provided?: DraggableProvided;
  isModule?: boolean;
  isSelected?: boolean;
  onChange?: (value: ICheck) => void;
  hasMenu?: boolean;
  toggleToast?: (state: string) => void;
  className?: string;
  isMultiDragging?: boolean;
  draggingCount?: number;
}

export default ComponentContainer;
