import React from "react";
import { NavLink } from "react-router-dom";

import { SubNav, MenuItem } from "@ax/components";
import { INavItem } from "@ax/types";
import { Restricted } from "@ax/guards";

import * as S from "./style";

const Nav = (props: INavProps): JSX.Element => {
  const { current, items, onClick } = props;

  return (
    <SubNav>
      {items &&
        items.map((item: INavItem, key: number) => {
          const isSelected = (current && item.title === current.title) || false;
          const selectedClass = isSelected ? "selected" : "";
          const handleClick = () => onClick(item.path);

          const menuItem = (
            <MenuItem onClick={handleClick} key={key}>
              <NavLink to="#" className={selectedClass}>
                <S.Link active={isSelected} data-testid="nav-link">
                  {item.title}
                </S.Link>
              </NavLink>
            </MenuItem>
          );

          return item.permission ? (
            <Restricted to={item.permission} key={key}>
              {menuItem}
            </Restricted>
          ) : (
            menuItem
          );
        })}
    </SubNav>
  );
};

export interface INavProps {
  current?: INavItem;
  items: INavItem[];
  onClick(path: string): void;
}

export default Nav;
