import styled from "styled-components";

const ExtendedAction = styled.div<{ onlyOnHover: boolean }>`
  display: ${(p) => (p.onlyOnHover ? "none" : "block")};
  position: absolute;
  right: ${(p) => p.theme.spacing.xxs};
  top: 50%;
  transform: translateY(-50%);
`;

const SubItem = styled.li<{ active: boolean }>`
  background-color: ${(p) => (p.active ? p.theme.color.overlayPressedPrimary : `transparent`)};
  display: block;
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  clear: both;
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  margin-top: ${(p) => p.theme.spacing.xxs};
  position: relative;

  &:hover {
    cursor: pointer;

    ${ExtendedAction} {
      display: block;
    }
  }

  > a {
    display: flex;
    padding: ${(p) => p.theme.spacing.xs};
    border-radius: ${(p) => p.theme.radii.s};
    color: inherit;

    &.selected {
      background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    }

    &:hover {
      background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    }
    &:active {
      background-color: ${(p) => p.theme.color.overlayPressedPrimary};
    }
  }
`;

export { ExtendedAction, SubItem };
