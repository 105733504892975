import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { SubNav, MenuItem, GuardModal } from "@ax/components";
import { IRootState } from "@ax/types";
import { useModal } from "@ax/hooks";
import { menuActions } from "@ax/containers/Navigation";

import * as S from "./style";

const Nav = (props: INav): JSX.Element => {
  const { getCurrentMenu, currentType, setCurrentType, isDirty, currentMenus } = props;
  const { isOpen, toggleModal } = useModal();

  const discardChanges = () => {
    getCurrentMenu(currentType);
    toggleModal();
  };

  return (
    <>
      <GuardModal isOpen={isOpen} discardChanges={discardChanges} toggleModal={toggleModal} />
      <SubNav>
        {currentMenus &&
          currentMenus.map((category: any, index: number) => {
            const _handleClick = () => {
              setCurrentType(category.name);
              isDirty ? toggleModal() : getCurrentMenu(category.name);
            };

            const isEmpty = currentType === "" && index === 0;

            const isSelected = isEmpty ? true : category.name === currentType;
            const selectedClass = isSelected ? "selected" : "";

            return (
              <MenuItem onClick={_handleClick} key={category.name}>
                <NavLink to="#" className={selectedClass}>
                  <S.Link active={isSelected}>{category.title}</S.Link>
                </NavLink>
              </MenuItem>
            );
          })}
      </SubNav>
    </>
  );
};

interface IProps {
  currentType: string;
  isDirty: boolean;
  currentMenus: any[] | null;
  setCurrentType(currentType: string): void;
}

interface IDispatchProps {
  getCurrentMenu(menuType: string): void;
}

const mapDispatchToProps = {
  getCurrentMenu: menuActions.getCurrentMenu,
};

const mapStateToProps = (state: IRootState) => ({
  currentMenus: state.menu.savedMenus,
});

type INav = IProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
