import * as React from "react";

export type UseFetchStateProps<T> = {
	isError: boolean;
	isLoading: boolean;
	msg: { code: number; msg: string };
	data?: T;
	isFirstFetch: boolean;
};

export function useFetch<ApiModelWithContentType>(url?: string) {
	const [data, setData] = React.useState<ApiModelWithContentType>();
	const [isLoading, setIsLoading] = React.useState(true);
	const [isError, setIsError] = React.useState(false);
	const [isFirstFetch, setIsFirstFetch] = React.useState(true);
	const [msg, setMsg] = React.useState(
		{} as UseFetchStateProps<ApiModelWithContentType>["msg"]
	);

	React.useEffect(() => {
		if (!url) return;
		setIsLoading(true);
		fetch(url)
			.then((response) => {
				setIsFirstFetch(false);
				if (response.status !== 200) {
					setIsLoading(false);
					setIsError(true);
					setMsg({
						code: response.status,
						msg: response.statusText,
					});
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((data: ApiModelWithContentType & { code: number; msg: string }) => {
				setData(data);
			})
			.finally(() => {
				setIsLoading(false);
				setIsError(false);
			})
			.catch(() => {
				setIsError(true);
			});
	}, [url]);

	return { data, isLoading, isError, isFirstFetch, msg };
}
