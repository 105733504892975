/* beautiful-react-hooks version: 0.35.0 */
import { slicedToArray as _slicedToArray, objectSpread2 as _objectSpread2 } from './_virtual/_rollupPluginBabelHelpers.js';
import { useEffect } from 'react';
import createHandlerSetter from './utils/createHandlerSetter.js';

var defaultOptions = {
  capture: false,
  once: false,
  passive: false
};

var useGlobalEvent = function useGlobalEvent(eventName) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultOptions;
  var fn = arguments.length > 2 ? arguments[2] : undefined;

  var _createHandlerSetter = createHandlerSetter(fn),
      _createHandlerSetter2 = _slicedToArray(_createHandlerSetter, 2),
      callbackRef = _createHandlerSetter2[0],
      setCallbackRef = _createHandlerSetter2[1];

  var opts = _objectSpread2(_objectSpread2({}, defaultOptions), options || {});

  useEffect(function () {
    var cb = function cb(event) {
      if (callbackRef.current) {
        callbackRef.current(event);
      }
    };

    if (callbackRef.current && eventName) {
      window.addEventListener(eventName, cb, opts);
    }

    return function () {
      if (eventName) {
        window.removeEventListener(eventName, cb, opts);
      }
    };
  }, [eventName, options]);
  return setCallbackRef;
};

export default useGlobalEvent;
