import React, { useState } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";

import { useModal } from "@ax/hooks";
import { ICheck, IRedirect } from "@ax/types";
import { CheckField, ElementsTooltip, Modal } from "@ax/components";
import { redirectsActions } from "@ax/containers/Redirects";
import RedirectPanel from "../RedirectPanel";

import * as S from "./style";

const RedirectItem = (props: IRedirectItemProps): JSX.Element => {
  const {
    redirect,
    isSelected,
    onChange,
    deleteRedirect,
    toggleToast,
    setFormValues,
    formValues,
    addRedirect,
    currentFilter,
    isSiteItem,
  } = props;

  const { isOpen, toggleModal } = useModal();
  const [isOpenedSecond, setIsOpenedSecond] = useState(false);
  const { isOpen: isOpenDelete, toggleModal: toggleModalDelete } = useModal();

  const toggleSecondaryPanel = () => setIsOpenedSecond(!isOpenedSecond);

  const handleClick = () => {
    const mappedRedirect = { ...redirect, to: { ...redirect.to, linkTo: redirect.to.pageId, href: redirect.to.url } };
    setFormValues(mappedRedirect);
    setIsOpenedSecond(false);
    toggleModal();
  };

  const handleOnChange = (value: ICheck) => onChange(value);

  const removeItem = async () => {
    if (redirect.id) {
      const deleted = await deleteRedirect(redirect.id, currentFilter);
      if (deleted) {
        toggleToast();
      }
    }
  };

  const menuOptions = [
    {
      label: "delete",
      icon: "delete",
      action: toggleModalDelete,
    },
  ];

  const mainDeleteModalAction = {
    title: "Delete redirect",
    onClick: removeItem,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleModalDelete };
  const target =
    redirect.site?.siteUrl === "/"
      ? `(${redirect.domain || ""})`
      : `(${redirect.domain || ""}|${redirect.site?.siteUrl || ""})`;
  const regex = new RegExp(target, "g");

  const isUrlAndDomainEqual = () => {
    const urlWithSlash = `${redirect.to?.url}/`;
    const domainWithSlash = `${redirect.domain}/`;

    return (
      urlWithSlash === redirect.domain ||
      urlWithSlash === domainWithSlash ||
      domainWithSlash === redirect.to?.url ||
      redirect.domain === redirect.to?.url
    );
  };

  return (
    <>
      <S.ItemRow role="rowgroup" selected={isSelected}>
        <S.CheckCell role="cell">
          <CheckField name="check" value={redirect.id ?? ""} checked={isSelected} onChange={handleOnChange} />
        </S.CheckCell>
        {!isSiteItem && (
          <S.SiteCell role="cell" onClick={handleClick}>
            {redirect.site && redirect.site.siteName && (
              <ElementsTooltip elements={[redirect.site.siteName]} maxChar={20} />
            )}
          </S.SiteCell>
        )}
        <S.UrlCell role="cell" onClick={handleClick}>
          {redirect.from.replace(regex, "")}
        </S.UrlCell>
        <S.UrlCell role="cell" onClick={handleClick}>
          {isUrlAndDomainEqual() ? "/" : redirect.to?.url?.replace(regex, "") || "Deleted page"}
        </S.UrlCell>
        <S.DateCell role="cell" onClick={handleClick}>
          {redirect.date && format(new Date(redirect.date), "dd MMM yyyy")}
        </S.DateCell>
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />
        </S.ActionsCell>
      </S.ItemRow>
      {isOpen && (
        <RedirectPanel
          isOpen={isOpen}
          isOpenedSecond={isOpenedSecond}
          toggleModal={toggleModal}
          toggleSecondaryPanel={toggleSecondaryPanel}
          redirect={redirect}
          setFormValues={setFormValues}
          formValues={formValues}
          addRedirect={addRedirect}
          currentFilter={currentFilter}
        />
      )}
      <Modal
        isOpen={isOpenDelete}
        hide={toggleModalDelete}
        title="Delete Redirect"
        secondaryAction={secondaryDeleteModalAction}
        mainAction={mainDeleteModalAction}
        size="S"
      >
        <S.ModalContent>
          Are you sure you want to delete <strong>{redirect.from} redirect</strong>? This action{" "}
          <strong>cannot be undone</strong>.
        </S.ModalContent>
      </Modal>
    </>
  );
};

interface IProps {
  redirect: IRedirect;
  isSelected: boolean;
  onChange: (e: any) => void;
  toggleToast: () => void;
  setFormValues: (redirect: IRedirect) => void;
  formValues: IRedirect;
  addRedirect: (force?: boolean) => void;
  currentFilter: string;
  isSiteItem: boolean;
}

interface IDispatchProps {
  deleteRedirect(redirectID: number, filter?: string): Promise<boolean>;
}

type IRedirectItemProps = IProps & IDispatchProps;

const mapDispatchToProps = {
  deleteRedirect: redirectsActions.deleteRedirect,
};

export default connect(null, mapDispatchToProps)(RedirectItem);
