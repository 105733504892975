import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { IRootState } from "@ax/types";
import { useModal } from "@ax/hooks";
import { dataPacksActions } from "@ax/containers/Settings";
import { MenuItem, SubNav, InformativeMenu, GuardModal } from "@ax/components";

import * as S from "./style";

const Nav = (props: IProps): JSX.Element => {
  const { selected, getActivatedDataPack, activated, isDirty } = props;
  const [selectedID, setSelectedID] = useState("");

  const { isOpen, toggleModal } = useModal();

  const selectPack = (packID: string | null) => getActivatedDataPack(packID);

  const resetFilter = () => {
    setSelectedID("");
    isDirty ? toggleModal() : selectPack(null);
  };

  const selectedClass = selected ? "" : "selected";

  const infoMenuProps: { icon: string; message: string; actionText: string; iconSize: "s" } = {
    icon: "alert",
    message: "You need to configure this package’s settings.",
    actionText: "Got it",
    iconSize: "s",
  };

  const discardChanges = () => {
    selectPack(selectedID);
    toggleModal();
  };

  return (
    <>
      <GuardModal isOpen={isOpen} discardChanges={discardChanges} toggleModal={toggleModal} />
      <SubNav>
        <S.Item>
          <S.Heading>Your packages</S.Heading>
          <MenuItem onClick={resetFilter}>
            <NavLink to="#" className={selectedClass}>
              <S.Link active={!selected}>
                Activated packages
              </S.Link>
            </NavLink>
          </MenuItem>
        </S.Item>

        <S.Item>
          <S.Heading>Configuration</S.Heading>
          {activated &&
            activated.map((dataPack: any, key: number) => {
              const { id, title, templates, config } = dataPack;
              const isFromPage = templates && templates.length > 0;
              const isSelected = id === (selected && selected.id);
              const selectedClass = isSelected ? "selected" : "";
              const _handleClick = () => {
                setSelectedID(id);
                isDirty ? toggleModal() : selectPack(id);
              };
              return (
                <MenuItem key={key} onClick={_handleClick}>
                  <NavLink to="#" className={selectedClass}>
                    <S.LinkWrapper>
                      <S.Link active={isSelected}>
                        {title}
                      </S.Link>
                      {isFromPage && !config && <InformativeMenu {...infoMenuProps} />}
                    </S.LinkWrapper>
                  </NavLink>
                </MenuItem>
              );
            })}
        </S.Item>
      </SubNav>
    </>
  );
};

interface IProps {
  selected: any;
  getActivatedDataPack: (packID: string | null) => void;
  activated: any;
  isDirty: boolean;
}

const mapDispatchToProps = {
  getActivatedDataPack: dataPacksActions.getActivatedDataPack,
};

const mapStateToProps = (state: IRootState) => ({
  selected: state.dataPacks.selected,
  activated: state.dataPacks.activated,
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
