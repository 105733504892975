export const filterStructure = [
  {
    title: "Unique pages",
    filters: [
      {
        name: "All",
        value: "unique-pages"
      }
    ]
  },
  {
    title: "Content Types",
    filters: []
  }
];
