/* beautiful-react-hooks version: 0.35.0 */
import { slicedToArray as _slicedToArray } from './_virtual/_rollupPluginBabelHelpers.js';
import { useState, useEffect } from 'react';
import safelyParseJson from './utils/safelyParseJson.js';
import isClient from './utils/isClient.js';
import isApiSupported from './utils/isAPISupported.js';
import isDevelopment from './utils/isDevelopment.js';

var useStorage = function useStorage(type) {
  var storageName = "".concat(type, "Storage");

  if (isClient && !isApiSupported(storageName)) {
    console.warn("".concat(storageName, " is not supported"));
  }

  return function (storageKey, defaultValue) {
    if (!isClient) {
      if (isDevelopment) {
        console.warn("Please be aware that ".concat(storageName, " could not be available during SSR"));
      }

      return [JSON.stringify(defaultValue), function () {
        return undefined;
      }];
    }

    var storage = window[storageName];

    var _useState = useState(safelyParseJson(storage.getItem(storageKey) || JSON.stringify(defaultValue))),
        _useState2 = _slicedToArray(_useState, 2),
        value = _useState2[0],
        setValue = _useState2[1];

    useEffect(function () {
      storage.setItem(storageKey, JSON.stringify(value));
    }, [storageKey, value]);
    return [value, setValue];
  };
};

export default useStorage;
