import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from "react";
import { connect } from "react-redux";

import {
  Button,
  EmptyState,
  ErrorToast,
  Icon,
  IconAction,
  MainWrapper,
  TableList,
  Loading,
  Tooltip,
  Toast,
  Modal,
  Tabs,
  BackFolder,
  SearchField,
  SearchTagsBar,
  FilterTagsBar,
} from "@ax/components";
import {
  IBulkAction,
  IFile,
  IFilesFolder,
  IFolder,
  IFolderTree,
  IGetFolderParams,
  IQueryValue,
  IRootState,
} from "@ax/types";
import { useBulkSelection, useIsDirty, useModal, usePermission, useResizable, useToast } from "@ax/hooks";
import { fileDriveActions } from "@ax/containers/FileDrive";

import GridItem from "./GridItem";
import ListItem from "./ListItem";
import BulkListHeader from "./BulkListHeader";
import FolderItem from "./FolderItem";
import Breadcrumb from "./Breadcrumb";
import FolderTree from "./FolderTree";
import FileDragAndDrop from "./FileDragAndDrop";
import FileModal from "./FileModal";
import BulkGridHeader from "./BulkGridHeader";
import { DeleteFileModal, MoveItemModal, NewFolderModal, NotSavedModal } from "./atoms";
import { useFilterQuery, useSortedListStatus } from "./hooks";
import { getSortedListStatus } from "./utils";
import Type from "./FileFilters/Type";
import SortBy from "./FileFilters/SortBy";
import UploadItem from "./UploadItem";

import * as S from "./style";

const FileDrive = (props: IProps) => {
  const {
    currentFolderContent,
    currentFolderID,
    currentSiteID,
    getFolderContent,
    getFoldersTree,
    updateCurrentFolder,
    createNewFolder,
    deleteFolder,
    deleteFile,
    moveFile,
    updateDisplayMode,
    updateTab,
    downloadFiles,
    uploadFile,
    breadcrumb,
    isLoading,
    isSaving,
    displayMode,
    selectedTab,
  } = props;

  const {
    files: { totalItems, items },
    folders,
  } = currentFolderContent || { files: { totalItems: 0, items: [] }, folders: [] };

  const [isPanelOpen, setPanelOpen] = useState(false);
  const [fileSelected, setFileSelected] = useState<IFile | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<number>(currentFolderID || 0);
  const filesIds: number[] = items.map((file: any) => file.id) || [];
  const [galleryItems, setGalleryItems] = useState<number[]>(filesIds);
  const [galleryDelete, setGalleryDelete] = useState(true);
  const [numDocs, setNumDocs] = useState(1);
  const [progressItems, setProgressItems] = useState<number[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const { isOpen: isNewOpen, toggleModal: toggleNewModal } = useModal();
  const { isOpen: isUploadOpen, toggleModal: toggleUploadModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isMoveOpen, toggleModal: toggleMoveModal } = useModal();
  const { isOpen: isSaveOpen, toggleModal: toggleSaveModal } = useModal();
  const { isOpen, toggleModal } = useModal();
  const { isVisible, toggleToast, setIsVisible } = useToast();
  const { isVisible: isNewVisible, toggleToast: toggleNewToast, setIsVisible: setIsNewVisible } = useToast();
  const { isVisible: isDeleteVisible, toggleToast: toggleDeleteToast, setIsVisible: setIsDeleteVisible } = useToast();
  const { isVisible: isMoveVisible, toggleToast: toggleMoveToast, setIsVisible: setIsMoveVisible } = useToast();
  const {
    isVisible: isMoveFolderVisible,
    toggleToast: toggleMoveFolderToast,
    setIsVisible: setIsMoveFolderVisible,
  } = useToast();

  const [ref] = useResizable();

  const initFolderState = { name: "", folderID: currentFolderID };
  const [folderForm, setFolderForm] = useState<IFolderFormState>(initFolderState);
  const { setFiltersSelection, resetFilterQuery, filterValues, filterQuery } = useFilterQuery();
  const { sortedListStatus, setSortedListStatus } = useSortedListStatus();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const isSearching = searchQuery.length > 0;

  const initFileState: IFileFormState = { title: "", alt: "", tags: [] };
  const [fileForm, setFileForm] = useState(initFileState);
  const { isDirty, resetDirty } = useIsDirty(fileForm);

  const isSiteView = !!currentSiteID;
  const isTabGlobal = selectedTab === "global";
  const siteID: number | "global" = !isSiteView || isTabGlobal ? "global" : currentSiteID;
  const hasFolders = !!folders.length;
  const isRoot = !breadcrumb.length;
  const isGrid = displayMode === "grid";
  const validFormats = ["pdf", "doc", "docx", "xls", "xlsx", "zip", "csv", "txt"];

  const allowedToAccessGlobalFromSite = usePermission("mediaGallery.accessToGlobalFileDriveFromSite");

  const allowedToAddSiteFile = usePermission("mediaGallery.addFiles");
  const allowedToAddGlobalFile = usePermission("global.mediaGallery.addGlobalFiles");
  const allowedToAddGlobalFileFromSite = usePermission("mediaGallery.addGlobalFilesFromSite");
  const allowedToAddFile = isTabGlobal
    ? allowedToAddGlobalFileFromSite
    : isSiteView
    ? allowedToAddSiteFile
    : allowedToAddGlobalFile;

  const allowedToEditSiteFile = usePermission("mediaGallery.editFiles");
  const allowedToEditGlobalFile = usePermission("global.mediaGallery.editGlobalFiles");
  const allowedToEditGlobalFileFromSite = usePermission("mediaGallery.editGlobalFilesInSite");
  const allowedToEditFile = isTabGlobal
    ? allowedToEditGlobalFileFromSite
    : isSiteView
    ? allowedToEditSiteFile
    : allowedToEditGlobalFile;

  const allowedToDeleteSiteFile = usePermission("mediaGallery.deleteFiles");
  const allowedToDeleteGlobalFile = usePermission("global.mediaGallery.deleteGlobalFiles");
  const allowedToDeleteGlobalFileFromSite = usePermission("mediaGallery.deleteGlobalFilesInSite");
  const allowedToDeleteFile = isTabGlobal
    ? allowedToDeleteGlobalFileFromSite
    : isSiteView
    ? allowedToDeleteSiteFile
    : allowedToDeleteGlobalFile;

  const getParams = useCallback(() => {
    const params = {
      siteID,
      folderID: currentFolderID,
      search: searchQuery,
      query: filterQuery,
      loading: true,
    };

    return params;
  }, [currentFolderID, selectedTab, searchQuery, filterQuery]);

  useLayoutEffect(() => {
    return () => {
      updateCurrentFolder(null);
      updateTab("site");
    };
  }, []);

  useEffect(() => {
    const handleGetContent = async () => {
      const params = getParams();
      await getFolderContent(params);
    };
    handleGetContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams]);

  useEffect(() => {
    const handleGetTree = async () => await getFoldersTree(siteID);
    handleGetTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    const filesIds: number[] = items.map((file: any) => file.id) || [];
    setGalleryItems(filesIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
  } = useBulkSelection(filesIds);

  const handleSelectedTab = (tab: "site" | "global") => {
    if (tab !== selectedTab) {
      setSearchQuery("");
      updateCurrentFolder(null);
      updateTab(tab);
    }
  };

  const handleNewFolder = () => {
    setFolderForm(initFolderState);
    !isNewOpen && toggleNewModal();
  };

  const changeDisplayMode = (mode: "grid" | "list") => updateDisplayMode(mode);

  const selectItems = () => (checkState.isAllSelected ? resetBulkSelection() : selectAllItems());

  const handleBackClick = () => {
    const parentID = breadcrumb.length >= 2 ? breadcrumb[breadcrumb.length - 2].id : null;
    updateCurrentFolder(parentID);
  };

  const togglePanel = () => setPanelOpen(!isPanelOpen);

  const handleCreateNewFolder = async () => {
    const isCreated = await createNewFolder({
      site: siteID,
      folderName: folderForm.name,
      parentId: folderForm.folderID || undefined,
    });
    if (isCreated) {
      isNewOpen && toggleNewModal();
      toggleNewToast();
    }
  };

  const handleDeleteFolder = async (folder: IFolder) => {
    const isDeleted = await deleteFolder(folder);
    if (isDeleted) {
      toggleToast();
    }
  };

  const handleClick = (file: IFile) => {
    setFileSelected(file);
    toggleModal();
  };

  const handleUpload = async (files: IFile[]) => {
    setFileSelected(files[0]);
    isUploadOpen && toggleUploadModal();
    toggleModal();
    const params = getParams();
    await getFolderContent(params);
  };

  const uploadMultipleFiles = async (files: File[], progressArray: number[]) => {
    try {
      let index = 0;
      while (files.length) {
        const handleProgress = (progress: number) => {
          setProgressItems((prevState) => [...prevState.slice(0, index), progress, ...prevState.slice(index + 1)]);
        };

        const file = files.shift();
        file && (await uploadFile(file, currentFolderID, siteID || "global", handleProgress));
        index = index + 1;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMultipleUpload = async (files: File[]) => {
    isUploadOpen && toggleUploadModal();

    const initialProgress = files.map(() => 0);
    setProgressItems(initialProgress);

    await uploadMultipleFiles(files, initialProgress);

    setProgressItems([]);
    const params = getParams();
    await getFolderContent(params);
  };

  const handleCloseModal = () => {
    if (isDirty) {
      toggleSaveModal();
    } else {
      resetFileModal();
    }
  };

  const resetFileModal = () => {
    setFileSelected(null);
    setGalleryItems(filesIds);
    setGalleryDelete(true);
    resetDirty();
    toggleModal();
  };

  const handleDeleteFile = async (fileID: number) => {
    const isDeleted = await deleteFile(fileID, siteID);
    if (isDeleted) {
      setNumDocs(1);
      toggleDeleteToast();
    }
  };

  const handleBulkDeleteFile = async () => {
    const isDeleted = await deleteFile(selectedItems.all, siteID);
    if (isDeleted) {
      setNumDocs(selectedItems.all.length);
      toggleDeleteModal();
      toggleDeleteToast();
      resetBulkSelection();
    }
  };

  const handleMoveFile = async (fileID: number, folderID: number) => {
    const isMoved = await moveFile(fileID, folderID, siteID);
    if (isMoved) {
      setNumDocs(1);
      toggleMoveToast();
    }
  };

  const handleBulkMoveFile = async () => {
    const isMoved = await moveFile(selectedItems.all, selectedFolder, siteID);
    if (isMoved) {
      setNumDocs(selectedItems.all.length);
      toggleMoveModal();
      toggleMoveToast();
      setSelectedFolder(0);
      resetBulkSelection();
    }
  };

  const handleBulkEditFile = () => {
    const currentFile = items.find((file: IFile) => file.id === selectedItems.all[0]);
    if (currentFile) {
      setGalleryItems(selectedItems.all);
      setFileSelected(currentFile);
      setGalleryDelete(false);
      toggleModal();
    }
  };

  const handleModalClose = () => {
    setSelectedFolder(0);
    isMoveOpen && toggleMoveModal();
  };

  const handleSelectFile = (id: number) => {
    const currentFile = items.find((file: IFile) => file.id === id);
    if (currentFile) {
      setFileSelected(currentFile);
    }
  };

  const handleDownloadBulk = async () => {
    await downloadFiles(selectedItems.all, true);
    resetBulkSelection();
  };

  const handleUpdateCurrentFolder = (folderID: number | null) => {
    setSearchQuery("");
    updateCurrentFolder(folderID);
  };

  const handleDownload = async (fileID: number, fileName: string) => await downloadFiles(fileID, false, fileName);

  const sortItems = (orderPointer: IQueryValue[], isAscending: boolean) => {
    const sortedState = getSortedListStatus(orderPointer[0].value.toString(), isAscending);
    setSortedListStatus(sortedState);
    setFiltersSelection("order", orderPointer, isAscending);
  };

  const filterItems = (filterPointer: string, filtersSelected: IQueryValue[]) =>
    setFiltersSelection(filterPointer, filtersSelected);

  let bulkActions: IBulkAction[] = [];

  if (allowedToEditFile) {
    bulkActions = [
      {
        icon: "edit",
        text: "edit",
        action: handleBulkEditFile,
      },
      {
        icon: "change",
        text: "Move to",
        action: toggleMoveModal,
      },
    ];
  }

  bulkActions = [
    ...bulkActions,
    {
      icon: "download",
      text: "download",
      action: handleDownloadBulk,
    },
  ];

  if (allowedToDeleteFile) {
    bulkActions = [
      ...bulkActions,
      {
        icon: "delete",
        text: "delete",
        action: toggleDeleteModal,
      },
    ];
  }

  const Header = (
    <BulkListHeader
      showBulk={areItemsSelected(filesIds)}
      checkState={checkState}
      selectAllItems={selectItems}
      totalItems={totalItems}
      selectItems={selectItems}
      bulkActions={bulkActions}
      isSearching={isSearching}
    />
  );

  const GridList = () => (
    <S.GridWrapper isSearching={isSearching}>
      {progressItems.map((progress: number, index: number) => (
        <UploadItem uploadPercentage={progress} grid={true} key={index} />
      ))}
      {items.map((file: IFile, i: number) => {
        const isItemSelected = isSelected(file.id);
        return (
          <GridItem
            file={file}
            isSelected={isItemSelected}
            onChange={addToBulkSelection}
            key={`${file.fileName}${file.id}`}
            onClick={handleClick}
            onDelete={handleDeleteFile}
            onMove={handleMoveFile}
            currentFolderID={currentFolderID}
            isAllowedToDelete={allowedToDeleteFile}
            isAllowedToEdit={allowedToEditFile}
            isAllowedToMove={!isRoot || (isRoot && (folders.length > 0 || isSearching))}
            isSearching={isSearching}
          />
        );
      })}
    </S.GridWrapper>
  );

  const ListTable = () => (
    <TableList tableHeader={Header} hasFixedHeader={true} tableRef={tableRef} className="no-padding">
      <>
        {progressItems.map((progress: number, index: number) => (
          <UploadItem uploadPercentage={progress} grid={false} key={index} />
        ))}
        {items.map((file: IFile, i: number) => {
          const isItemSelected = isSelected(file.id);
          return (
            <ListItem
              file={file}
              isSelected={isItemSelected}
              onChange={addToBulkSelection}
              key={`${file.fileName}${file.id}`}
              onClick={handleClick}
              onDelete={handleDeleteFile}
              onMove={handleMoveFile}
              currentFolderID={currentFolderID}
              isAllowedToDelete={allowedToDeleteFile}
              isAllowedToEdit={allowedToEditFile}
              isAllowedToMove={!isRoot || (isRoot && (folders.length > 0 || isSearching))}
              isSearching={isSearching}
            />
          );
        })}
      </>
    </TableList>
  );

  const toastDeleteFolderProps = {
    setIsVisible,
    message: "1 folder deleted",
  };

  const toastNewProps = {
    setIsVisible: setIsNewVisible,
    message: "1 new folder created",
  };

  const toastDeleteFileProps = {
    setIsVisible: setIsDeleteVisible,
    message: `${numDocs} Document${numDocs > 1 ? "s" : ""} deleted`,
  };

  const toastMoveFileProps = {
    setIsVisible: setIsMoveVisible,
    message: `${numDocs} Document${numDocs > 1 ? "s" : ""} moved to a folder`,
  };

  const toastMoveFolderProps = {
    setIsVisible: setIsMoveFolderVisible,
    message: "1 Folder moved to another folder",
  };

  const rightButtonProps = allowedToAddFile
    ? {
        label: "Upload",
        action: () => toggleUploadModal(),
      }
    : undefined;

  const emptyProps = {
    message: allowedToAddFile ? "To start using files in your site, upload as many documents as you need" : "",
    button: allowedToAddFile ? "Upload file" : undefined,
    action: () => toggleUploadModal(),
  };

  const emptySearchStateProps = {
    icon: "search",
    title: "Oh! No Results Found",
    message: "We couldn’t find what you are looking for. Please, try another search.",
  };

  const mainNewModalAction = {
    title: isSaving ? "Saving..." : "Create New Folder",
    onClick: () => handleCreateNewFolder(),
    disabled: folderForm.name.trim().length === 0 || isSaving,
  };

  const secondaryNewModalAction = { title: "Cancel", onClick: toggleNewModal };

  const mainDeleteModalAction = {
    title: "Delete documents",
    onClick: () => handleBulkDeleteFile(),
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainMoveModalAction = {
    title: "Move",
    onClick: () => handleBulkMoveFile(),
  };

  const secondaryMoveModalAction = { title: "Cancel", onClick: handleModalClose };

  const handleMainAction = () => {
    toggleSaveModal();
    resetFileModal();
  };

  const mainSaveModalAction = {
    title: "Yes, discard changes",
    onClick: () => handleMainAction(),
  };

  const secondarySaveModalAction = { title: "Cancel", onClick: toggleSaveModal };

  const foldersIcon = isPanelOpen ? <Icon name="closePanel" size="24" /> : <Icon name="openPanel" size="24" />;

  const tabs = ["site", "global"];

  const NewFolderButton = () =>
    allowedToAddFile ? (
      <S.ButtonWrapper>
        <Button type="button" onClick={handleNewFolder} buttonStyle="line" icon="NewFolder">
          New Folder
        </Button>
      </S.ButtonWrapper>
    ) : (
      <></>
    );

  const filters = (
    <S.Filters>
      <Type filterItems={filterItems} value={filterValues["filterType"]} />
      <SortBy sortItems={sortItems} sortedState={sortedListStatus} />
    </S.Filters>
  );

  return (
    <MainWrapper backLink={false} title="File Drive Manager" rightButton={rightButtonProps}>
      <S.Wrapper>
        <S.FolderPanel isOpen={isPanelOpen} ref={ref} style={{ width: isPanelOpen ? "240px" : "0" }}>
          <S.FolderPanelContent>
            <FolderTree
              folderID={currentFolderID || 0}
              onClick={handleUpdateCurrentFolder}
              title="Folders"
              createAction={allowedToAddFile ? handleNewFolder : undefined}
            />
          </S.FolderPanelContent>
          <S.ResizeHandle className="resizer" />
        </S.FolderPanel>
        <S.ContentWrapper>
          <ErrorToast />
          <S.FiltersBar isSite={isSiteView && allowedToAccessGlobalFromSite}>
            {isSiteView && allowedToAccessGlobalFromSite && (
              <S.TabsWrapper>
                <Tabs tabs={tabs} active={selectedTab} setSelectedTab={handleSelectedTab} noMargins />
              </S.TabsWrapper>
            )}
            {!isSiteView && filters}
            <S.DisplayModeWrapper>
              <IconAction icon="Grid2" onClick={() => changeDisplayMode("grid")} active={displayMode === "grid"} />
              <IconAction icon="BulletList" onClick={() => changeDisplayMode("list")} active={displayMode === "list"} />
            </S.DisplayModeWrapper>
          </S.FiltersBar>
          <S.FiltersBar isSite={false}>
            {isSiteView && filters}
            <SearchField onChange={setSearchQuery} placeholder="Search" value={searchQuery} autoFocus={false} />
          </S.FiltersBar>
          {isLoading ? (
            <Loading />
          ) : (
            <S.FolderContent>
              <S.SearchTags>
                <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
                <FilterTagsBar
                  filters={filterValues}
                  setFilters={setFiltersSelection}
                  labels={{ filterType: "Type" }}
                  resetFilters={resetFilterQuery}
                />
              </S.SearchTags>
              {!isRoot && <Breadcrumb breadcrumb={breadcrumb} onClick={handleUpdateCurrentFolder} />}
              {(hasFolders || !isRoot) && (
                <S.SectionWrapper>
                  <S.SectionHeader>
                    <S.SectionTitle>
                      <div>Folders</div>
                      <S.FoldersIconWrapper onClick={togglePanel}>
                        <Tooltip content="Open folder panel">{foldersIcon}</Tooltip>
                      </S.FoldersIconWrapper>
                    </S.SectionTitle>
                    <NewFolderButton />
                  </S.SectionHeader>
                  <S.FoldersWrapper>
                    {!isRoot && <BackFolder onClick={handleBackClick} />}
                    <S.FoldersGrid>
                      {folders.map((folder: IFolder) => (
                        <FolderItem
                          folder={folder}
                          onClick={handleUpdateCurrentFolder}
                          onDelete={handleDeleteFolder}
                          key={`${folder.folderName}${folder.id}`}
                          toggleToast={toggleMoveFolderToast}
                          isAllowedToDelete={allowedToDeleteFile}
                          isAllowedToEdit={allowedToEditFile}
                          isAllowedToMove={!isRoot || (isRoot && (folders.length > 1 || isSearching))}
                        />
                      ))}
                    </S.FoldersGrid>
                  </S.FoldersWrapper>
                </S.SectionWrapper>
              )}
              <S.BigSectionWrapper>
                <S.SectionHeader>
                  {totalItems > 0 && <S.SectionTitle>Documents</S.SectionTitle>}
                  {totalItems > 0 && isGrid && (
                    <BulkGridHeader
                      showBulk={areItemsSelected(filesIds)}
                      checkState={checkState}
                      selectAllItems={selectItems}
                      totalItems={totalItems}
                      selectItems={selectItems}
                      bulkActions={bulkActions}
                    />
                  )}
                  {!hasFolders && isRoot && !isSearching && <NewFolderButton />}
                </S.SectionHeader>
                <S.DocumentsWrapper>{isGrid ? <GridList /> : <ListTable />}</S.DocumentsWrapper>
                {totalItems === 0 && !hasFolders && (
                  <S.EmptyStateWrapper data-testid="empty-state" margin={!isRoot}>
                    {isSearching ? <EmptyState {...emptySearchStateProps} /> : <EmptyState {...emptyProps} />}
                  </S.EmptyStateWrapper>
                )}
              </S.BigSectionWrapper>
            </S.FolderContent>
          )}
        </S.ContentWrapper>
      </S.Wrapper>
      {isNewOpen && (
        <NewFolderModal
          isOpen={isNewOpen}
          toggleModal={toggleNewModal}
          mainModalAction={mainNewModalAction}
          secondaryModalAction={secondaryNewModalAction}
          form={folderForm}
          setForm={setFolderForm}
        />
      )}
      <Modal isOpen={isUploadOpen} hide={toggleUploadModal} size="XL" title="Upload file">
        {isUploadOpen && (
          <FileDragAndDrop
            validFormats={validFormats}
            folderID={currentFolderID}
            handleUpload={handleUpload}
            handleMultipleUpload={handleMultipleUpload}
            siteID={siteID}
          />
        )}
      </Modal>
      <Modal isOpen={isOpen} hide={handleCloseModal} size="XL" title="Document details">
        {isOpen && fileSelected && (
          <FileModal
            file={fileSelected}
            toggleModal={handleCloseModal}
            onDelete={handleDeleteFile}
            items={galleryItems}
            setFile={handleSelectFile}
            activeDelete={galleryDelete && allowedToDeleteFile}
            setFileSelected={setFileSelected}
            isAllowedToEdit={allowedToEditFile}
            handleDownload={handleDownload}
            form={fileForm}
            setForm={setFileForm}
            isDirty={isDirty}
            resetDirty={resetDirty}
          />
        )}
      </Modal>
      {isDeleteOpen && (
        <DeleteFileModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
          title="these documents"
        />
      )}
      {isMoveOpen && (
        <MoveItemModal
          isOpen={isMoveOpen}
          toggleModal={handleModalClose}
          mainModalAction={mainMoveModalAction}
          secondaryModalAction={secondaryMoveModalAction}
          folder={selectedFolder}
          setFolder={setSelectedFolder}
        />
      )}
      {isSaveOpen && (
        <NotSavedModal
          isOpen={isSaveOpen}
          toggleModal={toggleSaveModal}
          mainModalAction={mainSaveModalAction}
          secondaryModalAction={secondarySaveModalAction}
        />
      )}
      {isVisible && <Toast {...toastDeleteFolderProps} />}
      {isDeleteVisible && <Toast {...toastDeleteFileProps} />}
      {isNewVisible && <Toast {...toastNewProps} />}
      {isMoveVisible && <Toast {...toastMoveFileProps} />}
      {isMoveFolderVisible && <Toast {...toastMoveFolderProps} />}
    </MainWrapper>
  );
};

interface IProps {
  isLoading: boolean;
  isSaving: boolean;
  currentFolderContent: IFilesFolder | null;
  currentFolderID: number | null;
  currentSiteID: number | null;
  breadcrumb: IFolderTree[];
  displayMode: "grid" | "list";
  selectedTab: "site" | "global";
  getFolderContent(params: IGetFolderParams): Promise<void>;
  getFoldersTree(siteID: number | "global"): Promise<void>;
  updateCurrentFolder(folderID: number | null): void;
  createNewFolder(data: { folderName: string; site: number | "global"; parentId?: number }): Promise<boolean>;
  deleteFolder(folder: IFolder): Promise<boolean>;
  deleteFile(fileID: number | number[], siteID: number | "global"): Promise<boolean>;
  moveFile(fileID: number | number[], folderID: number, siteID: number | "global"): Promise<boolean>;
  updateDisplayMode(displayMode: "grid" | "list"): Promise<void>;
  updateTab(tab: "site" | "global"): Promise<void>;
  downloadFiles(fileID: number | number[], zip: boolean, fileName?: string): Promise<void>;
  uploadFile: (
    docFiles: File | File[],
    folderID: number | null,
    siteID: number | "global",
    setProgress?: (progress: number) => void
  ) => Promise<IFile | null>;
}

export interface IFolderFormState {
  name: string;
  folderID: number | null;
}

export interface IFileFormState {
  title: string;
  alt: string;
  tags: string[];
}

const mapDispatchToProps = {
  getFolderContent: fileDriveActions.getFolderContent,
  getFoldersTree: fileDriveActions.getFoldersTree,
  updateCurrentFolder: fileDriveActions.updateCurrentFolder,
  createNewFolder: fileDriveActions.createNewFolder,
  deleteFolder: fileDriveActions.deleteFolder,
  deleteFile: fileDriveActions.deleteFile,
  moveFile: fileDriveActions.moveFile,
  updateDisplayMode: fileDriveActions.updateDisplayMode,
  updateTab: fileDriveActions.updateTab,
  downloadFiles: fileDriveActions.downloadFiles,
  uploadFile: fileDriveActions.uploadFile,
};

const mapStateToProps = (state: IRootState) => ({
  isLoading: state.app.isLoading,
  isSaving: state.app.isSaving,
  currentFolderContent: state.fileDrive.currentFolderContent,
  currentFolderID: state.fileDrive.currentFolderID,
  breadcrumb: state.fileDrive.breadcrumb,
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  displayMode: state.fileDrive.displayMode,
  selectedTab: state.fileDrive.selectedTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(FileDrive);
