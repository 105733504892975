/* beautiful-react-hooks version: 0.35.0 */
import { useCallback } from 'react';
import throttle from 'lodash.throttle';

var defaultOptions = {
  leading: false,
  trailing: true
};

var useThrottledFn = function useThrottledFn(fn) {
  var wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultOptions;
  var dependencies = arguments.length > 3 ? arguments[3] : undefined;
  var throttled = throttle(fn, wait, options);
  return useCallback(throttled, dependencies);
};

export default useThrottledFn;
