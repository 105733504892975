import React from "react";

import { CheckField, TableCounter, TranslationsFilter } from "@ax/components";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps) => {
  const { totalItems, selectAllItems, isScrolling, filterItems, filterValues } = props;
  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          checked={false}
          disabled={false}
          error={false}
        />
      </S.CheckHeader>
      <S.NameHeader>Name</S.NameHeader>
      <S.CodeHeader>Code</S.CodeHeader>
      <S.SelectHeader>Selectable</S.SelectHeader>
      <S.TransHeader>
        <TranslationsFilter filterItems={filterItems} value={filterValues.translated} />
      </S.TransHeader>
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  filterValues: Record<string, IQueryValue[]>;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
}

export default TableHeader;
