import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import { IQueryValue, IRole } from "@ax/types";
import { usePermission } from "@ax/hooks";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    bulkDelete,
    selectItems,
    selectAllItems,
    totalItems,
    isScrolling,
    sortItems,
    filterItems,
    sortedListStatus,
    filterValues,
    isSiteView = false,
    roles,
  } = props;

  const isAllowedToRemoveUsers = usePermission("usersRoles.removeUsers");

  const bulkActions = isAllowedToRemoveUsers
    ? [
        {
          icon: "delete",
          text: isSiteView ? "Remove from this site" : "delete",
          action: bulkDelete,
        },
      ]
    : [];

  return showBulk ? (
    <BulkSelectionOptions
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader
      filterValues={filterValues}
      totalItems={totalItems}
      selectAllItems={selectAllItems}
      filterItems={filterItems}
      isScrolling={isScrolling}
      sortItems={sortItems}
      sortedListStatus={sortedListStatus}
      isSiteView={isSiteView}
      roles={roles}
    />
  );
};

interface IProps {
  showBulk: boolean;
  isSiteView?: boolean;
  checkState: Record<string, boolean>;
  bulkDelete: () => void;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  sortedListStatus: { isAscending: boolean; sortedByName: boolean; sortedByDateCreated: boolean };
  filterValues: Record<string, IQueryValue[]>;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  roles: IRole[];
}

export default BulkHeader;
