import React, { memo, useState } from "react";
import { Select, Icon } from "@ax/components";
import HourInput from "./HourInput";

import * as S from "./style";

const TimeField = (props: IProps) => {
  const { value, error, onChange, disabled } = props;

  const safeValue = !value || !value.length ? "00:00 am" : value;
  const splitValue = safeValue.split(" ");
  const initValue = { time: splitValue[0], meridian: splitValue[1] };
  const [state, setState] = useState(initValue);

  const handleChange = (newValue: string) => {
    setState({ ...state, time: newValue });
    onChange(`${newValue} ${state.meridian}`);
  };

  const handleSelectChange = (newValue: string) => {
    setState({ ...state, meridian: newValue });
    onChange(`${state.time} ${newValue}`);
  };

  const selectOptions = [
    { value: "am", label: "AM" },
    { value: "pm", label: "PM" },
  ];

  return (
    <S.FieldWrapper error={error} data-testid="time-field-wrapper" disabled={disabled}>
      <HourInput value={state.time} onChange={handleChange} disabled={disabled} />
      <S.SelectWrapper>
        <Select
          name="timeSelect"
          value={state.meridian}
          options={selectOptions}
          onChange={handleSelectChange}
          type="inline"
          disabled={disabled}
          mandatory={true}
        />
      </S.SelectWrapper>
      <S.IconWrapper>
        <Icon name="scheduled" size="24" />
      </S.IconWrapper>
    </S.FieldWrapper>
  );
};

interface IProps {
  value?: string;
  error?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export default memo(TimeField);
