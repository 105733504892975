import { IDataPack, IGriddoTheme, ITemplateOption, IThemeElements } from "@ax/types";
import { themes } from "components";

const getDefaultTheme = (): string => {
  const defaultTheme = (themes as IGriddoTheme[]).find((theme) => theme.default);
  const theme = defaultTheme ? defaultTheme.value : themes[0].value;
  return theme;
};

const getThemeElements = (theme: string): IThemeElements | undefined => {
  return (themes as IGriddoTheme[]).find((griddoTheme) => griddoTheme.value === theme)?.elements;
};

const filterThemeElements = (
  themeElements: IThemeElements | null,
  elements: string[],
  type: "modules" | "templates" | "datapacks"
): string[] => {
  if (themeElements === null) return elements;

  const { exclude, include } = themeElements;

  let filteredElements: string[] = elements;
  if (exclude && Array.isArray(exclude[type])) {
    filteredElements = elements.filter((element: string) => !exclude[type]?.includes(element));
  } else if (include && Array.isArray(include[type])) {
    filteredElements = elements.filter((element: string) => include[type]?.includes(element));
  }

  return filteredElements;
};

const filterThemeTemplates = (
  themeElements: IThemeElements | null,
  templates: ITemplateOption[]
): ITemplateOption[] => {
  if (themeElements === null) return templates;

  const { exclude, include } = themeElements;

  let filteredElements: ITemplateOption[] = templates;
  if (exclude && Array.isArray(exclude.templates)) {
    filteredElements = templates.filter((template) => !exclude.templates?.includes(template.value));
  } else if (include && Array.isArray(include.templates)) {
    filteredElements = templates.filter((template) => include.templates?.includes(template.value));
  }

  return filteredElements;
};

const filterThemeDatapacks = (themeElements: IThemeElements | null, datapacks: IDataPack[]): IDataPack[] => {
  if (themeElements === null) return datapacks;

  const { exclude, include } = themeElements;

  let filteredElements: IDataPack[] = datapacks;
  if (exclude && Array.isArray(exclude.datapacks)) {
    filteredElements = datapacks.filter((datapack) => !exclude.datapacks?.includes(datapack.id));
  } else if (include && Array.isArray(include.datapacks)) {
    filteredElements = datapacks.filter((datapack) => include.datapacks?.includes(datapack.id));
  }

  return filteredElements;
};

const isTemplateExcludedFromTheme = (themeElements: IThemeElements | null, template: string): boolean => {
  if (themeElements === null) return false;

  const { exclude, include } = themeElements;

  if (
    (exclude && Array.isArray(exclude.templates) && exclude.templates.includes(template)) ||
    (include && Array.isArray(include.templates) && !include.templates.includes(template))
  ) {
    return true;
  }

  return false;
};

export {
  getDefaultTheme,
  getThemeElements,
  filterThemeElements,
  filterThemeTemplates,
  filterThemeDatapacks,
  isTemplateExcludedFromTheme,
};
