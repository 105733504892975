import React from "react";
import styled from "styled-components";
import { Header } from "@ax/components/TableList/style";

const HeaderWrapper = styled((props) => <Header {...props} />)<{ isActive: boolean }>`
  width: 150px;
  justify-content: center;
  &:hover {
    color: ${(p) => p.theme.color.interactive01};
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    margin-left: 4px;
  }
`;

const ChecksWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  overflow-y: auto;
  max-height: 300px;
`;

export { HeaderWrapper, IconsWrapper, ChecksWrapper };
