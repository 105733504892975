import React from "react";
import { connect } from "react-redux";

import { FieldContainer } from "@ax/components";
import { navigationActions } from "@ax/containers/Navigation";
import { getInnerFields } from "@ax/forms";
import { IRootState } from "@ax/types";
import { areEqual, filterThemeElements } from "@ax/helpers";

const NavConnectedField = (props: any) => {
  const {
    whiteList,
    objKey,
    field,
    selectedContent,
    goTo,
    updateEditorContent,
    selectedEditorID,
    pages,
    actions,
    site,
    lang,
    currentDefaultsContent,
    activatedTemplates,
    menus,
    theme,
    disabled,
    moduleCopy,
    themeElements,
  } = props;

  const updateValue = (key: string, value: any) => {
    const isMenu = field.entity && field.entity === "menu_containers";
    if (isMenu) {
      const menu = menus.find((menu: any) => menu.id === value);
      updateEditorContent(selectedEditorID, key, menu);
    } else {
      updateEditorContent(selectedEditorID, key, value);
    }
  };

  const isSetAsDefaultField = objKey === "setAsDefault";
  const currentDefaultNav = currentDefaultsContent.find((item: any) => item.setAsDefault);
  const defaultNavId = currentDefaultNav && currentDefaultNav.id;
  const selectedContentId = selectedContent && selectedContent.id;
  const isDefaultNav = defaultNavId === selectedContentId;
  const isDisabled = isSetAsDefaultField && isDefaultNav;
  const isConditional = field.type === "ConditionalField";

  let isTemplateActivated = true;
  if (selectedContent.template) {
    isTemplateActivated = activatedTemplates.find((temp: any) => temp.id === selectedContent.template.templateType)
      ? true
      : false;
  }

  let innerFields: JSX.Element[] = [];

  if (isConditional) {
    const innerActions = { ...actions, updateValue, goTo };
    innerFields = getInnerFields(
      field.fields,
      innerActions,
      selectedContent,
      isTemplateActivated,
      theme,
      disabled,
      site
    );
  }

  const filteredWhiteList = whiteList ? filterThemeElements(themeElements, whiteList, "modules") : whiteList;

  return (
    <FieldContainer
      whiteList={filteredWhiteList}
      key={objKey}
      objKey={objKey}
      field={field}
      selectedContent={selectedContent}
      goTo={goTo}
      updateValue={updateValue}
      pages={pages}
      actions={actions}
      site={site}
      lang={lang}
      disabled={isDisabled}
      innerFields={innerFields}
      theme={theme}
      moduleCopy={moduleCopy}
    />
  );
};

const mapStateToProps = (state: IRootState) => ({
  selectedEditorID: state.navigation.selectedEditorID,
  selectedContent: { ...state.navigation.selectedContent },
  pages: state.sites.currentSitePages,
  site: state.sites.currentSiteInfo,
  lang: state.app.lang,
  currentDefaultsContent: state.navigation.currentDefaultsContent,
  activatedTemplates: state.dataPacks.templates,
  menus: state.menu.savedMenus,
  moduleCopy: state.navigation.moduleCopy,
  themeElements: state.sites.themeElements,
});

const mapDispatchToProps = {
  updateEditorContent: navigationActions.updateEditorContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NavConnectedField, areEqual));
