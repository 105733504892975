import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IImage, IRootState, IImageForm } from "@ax/types";
import { Button, CheckField, FieldsBehavior, Toast, IconAction } from "@ax/components";
import { formatBytes, getFileExtension, getFormattedDateWithTimezone } from "@ax/helpers";
import { usePermission } from "@ax/hooks";

import { galleryActions } from "@ax/containers/Gallery";
import { IIsSaving } from "@ax/containers/Gallery/reducer";
import * as S from "./style";

const GalleryDetailPanel = (props: IProps) => {
  const {
    imageSelected,
    isImageSelected,
    deleteImage,
    isGlobalTab,
    updateImage,
    refreshImages,
    isSaving,
    setImage,
    uploadImage,
    selectImage,
  } = props;

  const initialState: IImageForm = {
    id: undefined,
    alt: "",
    title: "",
    description: "",
    tags: [],
  };

  const [imageForm, setImageForm] = useState(initialState);
  const [addToGlobal, setAddToGlobal] = useState({ value: "addToGlobal", isChecked: false });
  const [deletedToast, setDeletedToast] = useState(false);

  const isAllowedToDelete =
    (isGlobalTab && usePermission("mediaGallery.deleteGlobalImagesInSite")) ||
    (!isGlobalTab && usePermission("mediaGallery.deleteImages"));
  const isAllowedToEdit =
    (isGlobalTab && usePermission("mediaGallery.editGlobalImagesInSite")) ||
    (!isGlobalTab && usePermission("mediaGallery.editImages"));
  const isAllowedToUploadGlobal = usePermission("mediaGallery.addGlobalImagesFromSite");

  const setInitForm = (imageSelected: IImage) => {
    const form = {
      id: imageSelected.id,
      title: imageSelected.title ? imageSelected.title : "",
      alt: imageSelected.alt ? imageSelected.alt : "",
      description: imageSelected.description ? imageSelected.description : "",
      tags: imageSelected.tags ? imageSelected.tags : [],
    };
    setImageForm(form);
  };

  const updateFormField = (field: string, value: string) => setImageForm((state) => ({ ...state, [field]: value }));

  useEffect(() => {
    if (imageSelected && imageSelected.id !== imageForm.id) {
      setInitForm(imageSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSelected]);

  const handleTitle = (newValue: string) => {
    updateFormField("title", newValue);
  };

  const handleAlt = (newValue: string) => {
    updateFormField("alt", newValue);
  };

  const handleDescription = (newValue: string) => {
    updateFormField("description", newValue);
  };

  const handleTags = (newValue: string) => {
    updateFormField("tags", newValue);
  };

  const handleSetAsGlobal = async () => {
    const saveAsGlobal = !isGlobalTab && addToGlobal.isChecked && imageSelected?.file;
    const image =
      saveAsGlobal && imageSelected && imageSelected.file && (await uploadImage(imageSelected.file, "global"));
    if (image && image.id) await updateImage(image.id, imageForm);
    setAddToGlobal({ value: "addToGlobal", isChecked: false });
  };

  const handleSave = async () => {
    if (imageForm.id) {
      await updateImage(imageForm.id, imageForm);
      await handleSetAsGlobal();
      refreshImages();
    }
  };

  const handleSaveAndAdd = async () => {
    if (imageForm.id) {
      await updateImage(imageForm.id, imageForm, true);
      await handleSetAsGlobal();
      setImage(imageForm);
    }
  };

  const handleAdd = async () => {
    if (imageForm.id) {
      setImage(imageForm);
    }
  };

  const handleDelete = async () => {
    if (imageForm.id) {
      const deleted = await deleteImage(imageForm.id);
      if (deleted) {
        selectImage(null);
        setDeletedToast(true);
      }
      refreshImages();
    }
  };

  const handleOpenUrl = () => {
    if (imageSelected) {
      const win = window.open(imageSelected.url, "_blank");
      if (win) {
        win.focus();
      }
    }
  };

  const handleCopyUrl = () => imageSelected && navigator.clipboard.writeText(imageSelected.url);

  const dimensions =
    imageSelected && imageSelected.width && imageSelected.height
      ? `${imageSelected.width}x${imageSelected.height}px`
      : "";

  const AddToGlobal = () => (
    <S.AddToGlobal data-testid="add-to-global-wrapper">
      <CheckField
        key="addToGlobal"
        title="Add to Global Gallery"
        name="addToGlobal"
        value="addToGlobal"
        checked={addToGlobal.isChecked}
        onChange={setAddToGlobal}
      />
    </S.AddToGlobal>
  );

  const canSetAsGlobal = !isGlobalTab && imageSelected?.file && isAllowedToUploadGlobal;

  const renderImageForm = !imageSelected ? null : (
    <S.PanelForm>
      {canSetAsGlobal && <AddToGlobal />}
      <S.ImageInfoWrapper>
        <S.ImageWrapper>
          <S.Image>
            <img src={imageSelected.url} alt={imageForm.alt} />
          </S.Image>
          <Button type="button" onClick={handleCopyUrl} buttonStyle="line">
            Copy URL
          </Button>
          <IconAction icon="OpenOutside" size="m" onClick={handleOpenUrl} />
        </S.ImageWrapper>
        <S.ImageName data-testid="gallery-image-name">{imageSelected.name}</S.ImageName>
        <S.Date>
          <strong>Uploaded:</strong> {getFormattedDateWithTimezone(imageSelected.published, "d MMM Y")}
        </S.Date>
        <S.Type>
          <strong>Type:</strong> {getFileExtension(imageSelected.name)}
        </S.Type>
        <S.ImageSize>
          <strong>Size:</strong> {formatBytes(imageSelected.size)}
        </S.ImageSize>
        <S.ImageDimensions>
          <strong>Resolution:</strong> {dimensions}
        </S.ImageDimensions>
      </S.ImageInfoWrapper>
      <S.FormWrapper>
        <FieldsBehavior
          title="Title"
          name="title"
          value={imageForm.title}
          fieldType="TextField"
          onChange={handleTitle}
          disabled={!isAllowedToEdit}
        />
        <FieldsBehavior
          title="Alternative text"
          name="alt"
          value={imageForm.alt}
          fieldType="TextField"
          onChange={handleAlt}
          disabled={!isAllowedToEdit}
        />
        <FieldsBehavior
          title="Description"
          name="description"
          value={imageForm.description}
          fieldType="TextArea"
          onChange={handleDescription}
          disabled={!isAllowedToEdit}
        />
        <FieldsBehavior
          title="Tags"
          value={imageForm.tags}
          fieldType="TagsField"
          onChange={handleTags}
          disabled={!isAllowedToEdit}
          helptext="Type a tag and press enter to create it"
        />
      </S.FormWrapper>
    </S.PanelForm>
  );

  return (
    <S.DetailPanelWrapper data-testid="detail-panel-wrapper" hidden={!isImageSelected}>
      {renderImageForm}
      <S.PanelActions>
        {isAllowedToDelete && (
          <Button
            type="button"
            buttonStyle="text"
            onClick={handleDelete}
            disabled={isSaving.save || isSaving.saveAdd || isSaving.delete}
          >
            {isSaving.delete ? `Deleting` : `Delete`}
          </Button>
        )}
        {isAllowedToEdit && (
          <Button
            type="button"
            buttonStyle="line"
            onClick={handleSave}
            disabled={isSaving.save || isSaving.saveAdd || isSaving.delete}
          >
            {isSaving.save ? `Saving` : `Save`}
          </Button>
        )}
        {isAllowedToEdit && (
          <Button
            type="button"
            onClick={handleSaveAndAdd}
            disabled={isSaving.save || isSaving.saveAdd || isSaving.delete}
          >
            {isSaving.saveAdd ? `Saving` : `Save & Add`}
          </Button>
        )}
        {!isAllowedToEdit && (
          <Button type="button" onClick={handleAdd} disabled={isSaving.delete}>
            Add
          </Button>
        )}
      </S.PanelActions>
      {deletedToast && <Toast message="1 image deleted" setIsVisible={setDeletedToast} />}
    </S.DetailPanelWrapper>
  );
};

export interface IDetailPanelProps {
  setImage: (imageData: any) => void;
  imageSelected: IImage | null;
  isImageSelected: boolean;
  isGlobalTab: boolean;
  refreshImages: () => void;
  isSaving: IIsSaving;
  selectImage(item: IImage | null): void;
}

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.gallery.isSaving,
});

export interface IDispatchProps {
  updateImage: (imageID: number, imageData: IImageForm, add?: boolean) => Promise<void>;
  deleteImage: (imageID: number) => Promise<boolean>;
  uploadImage: (imageFile: File, site: number | string) => Promise<IImage>;
}

const mapDispatchToProps = {
  updateImage: galleryActions.updateImage,
  deleteImage: galleryActions.deleteImage,
  uploadImage: galleryActions.uploadImage,
};

type IProps = IDetailPanelProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(memo(GalleryDetailPanel));
