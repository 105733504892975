const getDynamicFilters = (values: any) =>
  values &&
  values.map((value: any) => ({
    name: `${value.title}${value.local ? "" : " (Global)"}`,
    value: value.id,
    fromPage: value.fromPage,
    firstTemplate: value.schema.templates ? value.schema.templates[0] : null,
  }));

const getFilters = (staticFilters: any, dynamicValues: any) => {
  staticFilters &&
    staticFilters.forEach((item: any) => {
      if (!item.filters.length) {
        item.filters = getDynamicFilters(dynamicValues);
      }
    });
  return staticFilters;
};

export { getDynamicFilters, getFilters };
