import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { version } from "./../../../../../package.json";

import { IRouter, multisite, site } from "@ax/routes";

import { IRootState, ISite } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { Icon, Tag } from "@ax/components";
import { Restricted } from "@ax/guards";

import NavItem from "./NavItem";
import { NavProvider } from "./context";

import * as S from "./style";

const NavMenu = (props: IProps) => {
  const { location, setHistoryPush, logout, currentSiteInfo, siteLanguages, lang, categories } = props;

  const [isOpened, setIsOpened] = useState(false);

  const initState: Record<string, unknown> = {};
  const [state, setState] = useState(initState);

  const toggleSubmenu = (value: string) => {
    setState({ [value]: !state[value] });
  };

  const logoPlaceholder = "/img/logos/logoGriddoExtended@3x.svg";
  const logoMiniPlaceholder = "/img/logos/logoGriddoReduce@3x.svg";

  const toggleMenu = () => {
    setState({});
    setIsOpened(!isOpened);
  };

  const siteLogo = currentSiteInfo && currentSiteInfo.bigAvatar ? currentSiteInfo.bigAvatar : logoPlaceholder;
  const siteLogoMini =
    currentSiteInfo && currentSiteInfo.smallAvatar ? currentSiteInfo.smallAvatar : logoMiniPlaceholder;

  const sitesPath = "/sites/";
  const isSite = location.pathname.includes(sitesPath) && location.pathname.length > sitesPath.length;
  const siteSelector = location.pathname === "/sites";

  const goToPublishedSite = () => {
    const language = siteLanguages.find((l) => l.id === lang.id);
    if (language && language.home) {
      window.open(`${language.home}/`, "_blank");
    }
  };

  const goToSites = () => {
    !siteSelector && setHistoryPush("/sites");
  };

  const goToProfile = () => {
    const profileRoute = isSite ? "/sites/profile" : "/profile";
    setHistoryPush(profileRoute);
  };

  const config: IConfig = isSite
    ? {
        type: "site",
        routes: [...site],
        logo: (
          <>
            <S.LogoSite src={siteLogo} alt="Logo" />
            <S.LogoSiteMini src={siteLogoMini} alt="Logo" />
          </>
        ),
      }
    : {
        type: "multisite",
        routes: [...multisite],
        logo: (
          <>
            <S.Logo src={logoPlaceholder} alt="Logo Griddo" />
            <S.LogoSiteMini src={logoMiniPlaceholder} alt="Logo Griddo" />
          </>
        ),
      };

  const siteRoute = {
    component: "",
    name: "Visit Site",
    icon: "Web",
    path: "",
    onClick: goToPublishedSite,
  };

  const logoutRoute = {
    component: "",
    name: "Logout",
    icon: "Power",
    path: "",
    onClick: logout,
  };

  const profileRoute = {
    component: "",
    name: "Profile",
    icon: "User",
    path: isSite ? "/site/profile" : "/profile",
    onClick: goToProfile,
  };

  const toggleIcon = isOpened ? "Collapsed" : "Extend";
  const collapsedTitle = isOpened ? "Collapsed sidebar" : "Expanded sidebar";

  const collapseRoute = {
    component: "",
    name: collapsedTitle,
    icon: toggleIcon,
    path: "",
    onClick: toggleMenu,
  };

  const isSitePublished = isSite && currentSiteInfo && currentSiteInfo.isPublished;

  if (isSite) {
    const isSiteCategoriesAvailable = categories.site.length;
    const siteCategoriesRouteIdx = config.routes.findIndex((route: IRouter) => route.path === "/sites/categories");
    config.routes[siteCategoriesRouteIdx].showInNav = isSiteCategoriesAvailable;
  }

  return (
    <NavProvider value={{ state, toggleSubmenu }}>
      <S.NavWrapper type={config.type} isOpened={isOpened}>
        <S.Home type={config.type} onClick={goToSites} isSite={isSite} isOpened={isOpened} siteSelector={siteSelector}>
          {isSite && (
            <S.GoBack>
              <Icon name="LeftArrow" />
            </S.GoBack>
          )}
          <S.NavLink>{config.logo}</S.NavLink>
          {isOpened && !isSite ? <Tag type="square" text={`V ${version}`} color="rgba(80, 87, 255, 0.16)" /> : null}
        </S.Home>
        <S.Lists>
          <S.List>
            {config.routes &&
              config.routes
                .filter((route: IRouter) => route.showInNav)
                .map((route: IRouter): JSX.Element => {
                  const navItem = (
                    <NavItem
                      setHistoryPush={setHistoryPush}
                      key={route.name}
                      route={route}
                      location={location}
                      type={config.type}
                      isOpened={isOpened}
                    />
                  );

                  return route.permission ? (
                    <Restricted key={route.name} to={route.permission}>
                      {navItem}
                    </Restricted>
                  ) : (
                    navItem
                  );
                })}
          </S.List>
          <S.List>
            {isSitePublished && (
              <>
                <NavItem setHistoryPush={setHistoryPush} route={siteRoute} type={config.type} isOpened={isOpened} />
                <S.Separator />
              </>
            )}
            <NavItem
              setHistoryPush={setHistoryPush}
              route={profileRoute}
              type={config.type}
              isOpened={isOpened}
              location={location}
            />
            <NavItem setHistoryPush={setHistoryPush} route={logoutRoute} type={config.type} isOpened={isOpened} />
            <S.Separator />
            <NavItem setHistoryPush={setHistoryPush} route={collapseRoute} type={config.type} isOpened={isOpened} />
          </S.List>
        </S.Lists>
      </S.NavWrapper>
    </NavProvider>
  );
};

interface IDispatchProps {
  logout(): any;
  setHistoryPush(path: string): void;
}

interface IConfig {
  routes: IRouter[];
  type: string;
  logo: any;
}

interface INavMenuProps {
  categories: any;
  currentSiteInfo: ISite | null;
  siteLanguages: any[];
  lang: any;
}

const mapStateToProps = (state: IRootState) => ({
  categories: state.structuredData.categories,
  currentSiteInfo: state.sites.currentSiteInfo,
  siteLanguages: state.sites.currentSiteLanguages,
  lang: state.app.lang,
});

type IProps = INavMenuProps & IDispatchProps & RouteComponentProps;

const mapDispatchToProps = {
  logout: appActions.logout,
  setHistoryPush: appActions.setHistoryPush,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavMenu));
