import React from "react";

import { CheckField, TableCounter, SiteFilter, NameFilter, RoleFilter } from "@ax/components";
import { IQueryValue, IRole } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    totalItems,
    selectAllItems,
    isScrolling,
    sortItems,
    filterItems,
    sortedListStatus,
    filterValues,
    isSiteView,
    roles,
  } = props;

  const roleFilters = [
    {
      name: "all",
      value: "all",
      title: "All",
    },
  ];

  roles.forEach((item: IRole) => {
    const newFilter = {
      name: item.id.toString(),
      value: item.id.toString(),
      title: item.name,
    };
    item.active && roleFilters.push(newFilter);
  });

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          checked={false}
          disabled={false}
          error={false}
        />
      </S.CheckHeader>
      <S.NameWrapper>
        <NameFilter sortItems={sortItems} sortedState={sortedListStatus} pointer="name" />
      </S.NameWrapper>
      <S.RolesWrapper isSite={isSiteView}>
        <RoleFilter
          filterItems={filterItems}
          filters={roleFilters}
          value={filterValues}
          pointer="filterRoles"
          center={false}
        />
      </S.RolesWrapper>
      {!isSiteView && (
        <>
          <S.SiteWrapper>
            <SiteFilter
              filterItems={filterItems}
              value={filterValues}
              pointer="filterSites"
              center={false}
              label="Sites"
            />
          </S.SiteWrapper>
          <S.StatusHeader />
        </>
      )}
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  isSiteView: boolean;
  selectAllItems: () => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  sortedListStatus: { isAscending: boolean; sortedByName: boolean; sortedByDateCreated: boolean };
  filterValues: Record<string, IQueryValue[]>;
  roles: IRole[];
}

export default TableHeader;
