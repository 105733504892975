/* beautiful-react-hooks version: 0.35.0 */
var safelyParseJson = function safelyParseJson(parseString) {
  try {
    return JSON.parse(parseString);
  } catch (e) {
    return null;
  }
};

export default safelyParseJson;
