import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const { showBulk, checkState, bulkActions, selectItems, selectAllItems, totalItems, isScrolling } = props;

  return showBulk ? (
    <BulkSelectionOptions
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader totalItems={totalItems} selectAllItems={selectAllItems} isScrolling={isScrolling} />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: any;
  bulkActions: { icon: string; text: string; action: () => void }[];
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
}

export default BulkHeader;
