import React, { useState } from "react";
import { connect } from "react-redux";

import { useModal, usePermission } from "@ax/hooks";
import { getFormattedDateWithTimezone } from "@ax/helpers";
import { ICheck, IGetSitesParams, ISite } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { sitesActions } from "@ax/containers/Sites";
import { FieldsBehavior, Icon, Modal, Tooltip, CheckField } from "@ax/components";

import { ItemName, ItemThumbnail } from "./../../atoms";

import * as S from "./style";

const ListSiteItem = (props: IListSiteItemProps): JSX.Element => {
  const { site, setSiteInfo, setHistoryPush, deleteSite, publishSite, unpublishSite, isSelected, onCheck, getParams } =
    props;

  const { isOpen: isOpenDelete, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isOpenPublish, toggleModal: togglePublishModal } = useModal();
  const [inputValue, setInputValue] = useState("");
  const { updated, isPublished } = site;

  const allowedToDeleteSite = usePermission("general.deleteSite");
  const allowedToPublishSite = usePermission("general.publishSite");
  const allowedToUnpublishSite = usePermission("general.unpublishSite");

  const getPublishedState = (isPublished: boolean, updated: boolean) => {
    switch (isPublished) {
      case true:
        return updated ? "active" : "upload-pending";
      case false:
        return updated ? "offline" : "offline-pending";
    }
  };

  const publishedState = getPublishedState(isPublished, updated);

  const publishedTooltip = {
    active: "Live",
    "upload-pending": "Publication pending",
    offline: "Offline",
    "offline-pending": "Offline pending",
  };

  const setSite = async () => {
    await setSiteInfo(site);
    const contentPath = "/sites/pages";
    setHistoryPush(contentPath, false);
  };

  const getPublishOption = (isPublished: boolean) =>
    isPublished && allowedToUnpublishSite
      ? { label: "Unpublish", icon: "offlinePending" }
      : allowedToPublishSite
      ? { label: "Publish", icon: "uploadPending" }
      : null;

  const publishOptionProps = getPublishOption(isPublished);

  const deleteOption = allowedToDeleteSite
    ? {
        label: "delete",
        icon: "delete",
        action: toggleDeleteModal,
      }
    : undefined;

  const publishOption = publishOptionProps
    ? {
        label: publishOptionProps.label,
        icon: publishOptionProps.icon,
        action: togglePublishModal,
      }
    : undefined;

  const menuOptions = [deleteOption, publishOption];

  const handleDeleteSite = async () => {
    const params = getParams();
    await deleteSite(site.id, params);
    toggleDeleteModal();
  };

  const mainDeleteAction = {
    title: "Delete Site",
    onClick: handleDeleteSite,
    disabled: inputValue !== site.name.toUpperCase(),
  };
  const secondaryDeleteAction = { title: "Cancel", onClick: toggleDeleteModal };

  const handlePublishSite = async () => {
    const params = getParams();
    await publishSite(site.id, params);
    togglePublishModal();
  };

  const handleUnpublishSite = async () => {
    const params = getParams();
    await unpublishSite(site.id, params);
    togglePublishModal();
  };

  const getPublishModal = (isPublished: boolean) =>
    isPublished
      ? {
          mainAction: { title: "Unpublish Site", onClick: handleUnpublishSite },
          secondaryAction: { title: "Cancel", onClick: togglePublishModal },
          title: "Unpublish Site",
          content: (
            <p>
              You are going to unpublish <strong>{site.name}</strong> site.
              <br />
              This action can take several minutes.
            </p>
          ),
        }
      : {
          mainAction: { title: "Publish Site", onClick: handlePublishSite },
          secondaryAction: { title: "Cancel", onClick: togglePublishModal },
          title: "Publish Site",
          content: (
            <p>
              You are going to publish <strong>{site.name}</strong> site.
              <br />
              Make sure everything is ok before you do it.
            </p>
          ),
        };

  const { title, mainAction, secondaryAction, content } = getPublishModal(site.isPublished);

  const handleOnChange = (value: ICheck) => onCheck(value);

  return (
    <>
      <S.SiteItemRow key={site.id} role="rowgroup" selected={isSelected} data-testid="list-site-item">
        <S.CheckCell role="cell">
          <CheckField name="check" value={site.id} checked={isSelected} onChange={handleOnChange} />
        </S.CheckCell>
        <S.ThumbailCell role="cell" onClick={setSite}>
          <ItemThumbnail width={64} height={48} src={site.thumbnail} />
        </S.ThumbailCell>
        <S.NameCell role="cell" onClick={setSite}>
          <S.Title>
            <ItemName name={site.name} length={40} />
          </S.Title>
        </S.NameCell>
        <S.UrlCell role="cell" onClick={setSite}>
          {site?.home ? site?.home.replace("//", "") : ""}
        </S.UrlCell>
        <S.LiveCell role="cell" onClick={setSite}>
          <Tooltip content={publishedTooltip[publishedState]}>
            {publishedState && <Icon name={publishedState} size="24" />}
          </Tooltip>
        </S.LiveCell>
        <S.DateCell role="cell">
          {site?.lastAccess ? getFormattedDateWithTimezone(new Date(site?.lastAccess), "MMM d, Y") : ""}
        </S.DateCell>
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Site actions" />
        </S.ActionsCell>
      </S.SiteItemRow>
      <Modal
        isOpen={isOpenDelete}
        hide={toggleDeleteModal}
        title="Delete Site?"
        secondaryAction={secondaryDeleteAction}
        mainAction={mainDeleteAction}
      >
        {isOpenDelete ? (
          <S.ModalContent data-testid="delete-modal">
            <p>
              Are you sure you want to delete <strong>‘{site.name}’</strong> site?
              <br />
              This action <strong>cannot be undone</strong>.
            </p>
            <p>
              Please type <strong>{site.name.toUpperCase()}</strong> to confirm
            </p>
            <FieldsBehavior fieldType="TextField" placeholder="Type here" value={inputValue} onChange={setInputValue} />
          </S.ModalContent>
        ) : null}
      </Modal>
      <Modal
        isOpen={isOpenPublish}
        hide={togglePublishModal}
        title={title}
        secondaryAction={secondaryAction}
        mainAction={mainAction}
      >
        {isOpenPublish ? <S.ModalContent data-testid="publish-modal">{content}</S.ModalContent> : null}
      </Modal>
    </>
  );
};

interface IListSiteItemProps {
  site: ISite;
  isSelected: boolean;
  setSiteInfo(currentSiteInfo: ISite): Promise<void>;
  setHistoryPush(site: string, isEditor: boolean): void;
  deleteSite(siteID: number, params?: IGetSitesParams): Promise<void>;
  publishSite(siteID: number, params?: IGetSitesParams): Promise<void>;
  unpublishSite(siteID: number, params?: IGetSitesParams): Promise<void>;
  onCheck: (e: any) => void;
  getParams: () => IGetSitesParams;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  setSiteInfo: sitesActions.setSiteInfo,
  deleteSite: sitesActions.deleteSite,
  publishSite: sitesActions.publishSite,
  unpublishSite: sitesActions.unpublishSite,
};

export default connect(null, mapDispatchToProps)(ListSiteItem);
