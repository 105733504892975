const isEmptyArray = (arr: any[]) => arr && arr.length === 0;

const moveArrayElement = (element: any, arr: any[], newIndex: number): Array<any> => {
  const arrCopy = [...arr];
  if (arrCopy.length <= 1) return arrCopy;
  const elementIndex = arrCopy.findIndex((el: any) => el === element);
  const el = arrCopy[elementIndex];
  arrCopy.splice(elementIndex, 1);
  arrCopy.splice(newIndex, 0, el);
  return arrCopy;
};

export { isEmptyArray, moveArrayElement };
