/* beautiful-react-hooks version: 0.35.0 */
import { useRef, useCallback } from 'react';

var createHandlerSetter = function createHandlerSetter(handlerValue) {
  var handlerRef = useRef(handlerValue);
  var setHandler = useCallback(function (nextCallback) {
    if (typeof nextCallback !== 'function') {
      throw new Error('the argument supplied to the \'setHandler\' function should be of type function');
    }

    handlerRef.current = nextCallback;
  }, [handlerRef]);
  return [handlerRef, setHandler];
};

export default createHandlerSetter;
