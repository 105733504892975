/* beautiful-react-hooks version: 0.35.0 */
import { slicedToArray as _slicedToArray } from './_virtual/_rollupPluginBabelHelpers.js';
import { useState, useEffect } from 'react';
import isClient from './utils/isClient.js';
import isApiSupported from './utils/isAPISupported.js';

var errorMessage = 'matchMedia is not supported, this could happen both because window.matchMedia is not supported by' + ' your current browser or you\'re using the useMediaQuery hook whilst server side rendering.';

var useMediaQuery = function useMediaQuery(mediaQuery) {
  if (!isClient || !isApiSupported('matchMedia')) {
    console.warn(errorMessage);
    return null;
  }

  var _useState = useState(!!window.matchMedia(mediaQuery).matches),
      _useState2 = _slicedToArray(_useState, 2),
      isVerified = _useState2[0],
      setIsVerified = _useState2[1];

  useEffect(function () {
    var mediaQueryList = window.matchMedia(mediaQuery);

    var documentChangeHandler = function documentChangeHandler() {
      return setIsVerified(!!mediaQueryList.matches);
    };

    try {
      mediaQueryList.addEventListener('change', documentChangeHandler);
    } catch (e) {
      console.error(e);
      mediaQueryList.addListener(documentChangeHandler);
    }

    documentChangeHandler();
    return function () {
      try {
        mediaQueryList.removeEventListener('change', documentChangeHandler);
      } catch (e) {
        console.error(e);
        mediaQueryList.removeListener(documentChangeHandler);
      }
    };
  }, [mediaQuery]);
  return isVerified;
};

export default useMediaQuery;
