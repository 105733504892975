const getDynamicFilters = (values: any) =>
  values &&
  values.map((value: any) => ({
    name: value.title,
    value: value.id,
    fromPage: value.fromPage,
    editable: value.editable ? value.editable : false,
  }));

const getFilters = (staticFilters: any, dynamicValues: any) => {
  staticFilters &&
    staticFilters.forEach((item: any) => {
      if (!item.filters.length) {
        const dynamicFilters = getDynamicFilters(dynamicValues);
        item.filters = dynamicFilters.filter((filter: any) => filter.fromPage === item.fromPage);
      }
    });
  return staticFilters;
};

export { getDynamicFilters, getFilters };
