export const filterStructure = [
  {
    fromPage: true,
    filters: [
      {
        name: "All Pages",
        value: "all-pages",
      },
    ],
  },
  {
    title: "Pages",
    fromPage: true,
    filters: [],
  },
  {
    title: "Simple Content",
    fromPage: false,
    filters: [],
  },
];
