import styled from "styled-components";

const Wrapper = styled.section`
  width: 720px;
  margin: ${(p) => `${p.theme.spacing.m} 0 0 ${p.theme.spacing.m}`};
`;

const NotificationWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: ${(p) => `calc(${p.theme.spacing.s} * 4)`};
  left: 0;
  right: 0;
  z-index: 2;
`;

export { Wrapper, NotificationWrapper };
