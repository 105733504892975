import React from "react";
import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { IColumn } from "@ax/types";

import * as S from "./style";

const CustomizeFilters = (props: ICustomizeFiltersProps): JSX.Element => {
  const { columns, setColumns, value } = props;
  const options = Object.keys(columns).map((col: string) => {
    const disabled = value.length >= 5 && !value.includes(col);
    return { name: col, title: columns[col].title, value: col, disabled };
  });

  const toggleColumn = (selection: any) => {
    const updatedColumns = { ...columns };
    Object.keys(columns).forEach((val: string, index: number) => {
      if (selection.includes(val)) {
        updatedColumns[val].show = true;
      } else {
        updatedColumns[val].show = false;
      }
    });
    setColumns(updatedColumns);
  };

  const Button = () => (
    <S.IconsWrapper>
      <Icon name="settings" size="16" />
    </S.IconsWrapper>
  );

  return (
    <S.Wrapper data-testid="customize-filters-wrapper">
      <FloatingMenu Button={Button} position="right" closeOnSelect={false}>
        <ListTitle>Customize filters</ListTitle>
        <S.Note>You can select up to five options</S.Note>
        <S.ChecksWrapper>
          <CheckGroup options={options} value={value} onChange={toggleColumn} />
        </S.ChecksWrapper>
      </FloatingMenu>
    </S.Wrapper>
  );
};

export interface ICustomizeFiltersProps {
  setColumns(newValue: any): void;
  columns: Record<string, IColumn>;
  value: string[];
}

export default CustomizeFilters;
