import { Dispatch } from "redux";

import { handleRequest, isReqOk, compressImage } from "@ax/helpers";
import { images, sites } from "@ax/api";
import { IGetSiteImages, IImage, IImageForm, IRootState } from "@ax/types";
import { appActions } from "@ax/containers/App";

import {
  SET_DATA,
  SET_IS_LOADING,
  SET_IS_SAVING,
  SET_IS_UPLOADING,
  SET_UPLOAD_SUCCESS,
  SET_UPLOAD_ERROR,
} from "./constants";
import {
  ISetData,
  ISetIsLoading,
  ISetIsSaving,
  ISetIsUploading,
  ISetUploadSuccess,
  ISetUploadError,
} from "./interfaces";
import { IData, IIsLoading, IIsSaving } from "./reducer";

const thumbWidth = 192;
const thumbHeight = 144;
const maxSize = 10; // MB

function setData(data: IData): ISetData {
  return { type: SET_DATA, payload: { data } };
}

function setIsLoading(isLoading: IIsLoading): ISetIsLoading {
  return { type: SET_IS_LOADING, payload: { isLoading } };
}

function setIsSaving(isSaving: IIsSaving): ISetIsSaving {
  return { type: SET_IS_SAVING, payload: { isSaving } };
}

function setIsUploading(isUploading: boolean): ISetIsUploading {
  return { type: SET_IS_UPLOADING, payload: { isUploading } };
}

function setUploadSuccess(isSuccess: boolean): ISetUploadSuccess {
  return { type: SET_UPLOAD_SUCCESS, payload: { isUploading: false, isSuccess } };
}

function setUploadError(isError: boolean, errorMsg = ""): ISetUploadError {
  return { type: SET_UPLOAD_ERROR, payload: { isUploading: false, isError, errorMsg } };
}

function getSiteImages(props: IGetSiteImages): (dispatch: Dispatch, getState: () => IRootState) => Promise<void> {
  return async (dispatch, getState) => {
    const {
      gallery: { data },
    } = getState();
    const { site, page = 1, items, query, search, more = false } = props;
    try {
      dispatch(setIsLoading({ init: !more, more }));
      const result = await sites.getSiteImages(site, page, items, thumbWidth, thumbHeight, query, search);
      if (isReqOk(result.status)) {
        const images = [...result.data.items];
        if (more) images.unshift(...data.items);
        dispatch(
          setData({
            items: images,
            page,
            isFinished: result.data.items.length < items,
          })
        );
        dispatch(setIsLoading({ init: false, more: false }));
        dispatch(setUploadSuccess(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
}

function uploadImage(
  imageFiles: File | File[],
  site: number | string,
  setProgress?: (progress: number) => void
): (dispatch: Dispatch) => Promise<IImage> {
  return async (dispatch) => {
    try {
      dispatch(setIsUploading(true));

      const imageFilesArr = Array.isArray(imageFiles) ? imageFiles : [imageFiles];
      const uploadPromises = imageFilesArr.map(async (imageFile: File) => {
        const imageSize = imageFile.size / (1024 * 1024);
        const image = imageSize >= maxSize ? await compressImage(imageFile, 9999, 9999, maxSize, false) : imageFile;
        const form = new FormData();
        form.append("file", image);
        site && form.append("site", JSON.stringify(site));
        const createdImage = await images.createImage(form, setProgress);
        return { ...createdImage, image };
      });
      const results = await Promise.all(uploadPromises);
      const lastUploadedImage = results.reduce((lastResult, result) =>
        result.data.id > lastResult.data.id ? result : lastResult
      );

      if (isReqOk(lastUploadedImage?.status)) {
        dispatch(setIsUploading(false));
        dispatch(setUploadSuccess(true));
        return { ...lastUploadedImage.data, file: lastUploadedImage.image };
      } else {
        dispatch(setUploadError(true, "Error uploading image"));
      }
    } catch (e) {
      console.log(e);
    }
  };
}

function uploadError(error: boolean, msg?: string): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    dispatch(setUploadError(error, msg));
  };
}

function updateImage(
  imageID: number,
  imageData: IImageForm,
  add?: boolean
): (dispatch: Dispatch, getState: () => IRootState) => Promise<void> {
  return async (dispatch, getState) => {
    const {
      gallery: { isSaving },
    } = getState();
    dispatch(setIsSaving({ ...isSaving, save: true, saveAdd: !!add }));
    try {
      const result = await images.updateImage(imageID, imageData);
      if (isReqOk(result.status)) {
        dispatch(setData({ ...result.data, isImageSelected: false, imageSelected: null }));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch(setIsSaving({ ...isSaving, save: false, saveAdd: false }));
  };
}

function deleteImage(imageID: number): (dispatch: Dispatch, getState: () => IRootState) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        gallery: { data },
      } = getState();
      const responseActions = {
        handleSuccess: () => null,
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => images.deleteImage(imageID);

      return await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

export { setIsLoading, getSiteImages, uploadImage, updateImage, deleteImage, uploadError };
