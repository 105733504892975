import styled, { keyframes } from "styled-components";

const OpacityAnimation = keyframes`
  from  {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`;

const ActionMenu = styled.ul``;

const WrapperTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
  flex: 1 1 auto;
`;

const TabsContent = styled.div`
  position: absolute;
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
`;

const WrapperEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing.xl};
`;

const Title = styled.h1`
  margin: 0;
  ${(p) => p.theme.textStyle.headingM};
  padding-left: ${(p) => p.theme.spacing.m};
`;

const Subtitle = styled.span`
  display: block;
  ${(p) => p.theme.textStyle.headingXS};
`;

const ActionText = styled.span`
  padding-left: ${(p) => p.theme.spacing.xs};
  margin-left: ${(p) => p.theme.spacing.xxs};
`;

const ActionItem = styled.li`
  display: flex;
  align-items: center;
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.l};
  cursor: pointer;
  &:focus {
    background: ${(p) => p.theme.color.overlayFocusPrimary};
  }

  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }

  &.grey svg {
    path {
      fill: ${(p) => p.theme.color.iconMediumEmphasis};
    }
  }
`;

const ActionMenuTitle = styled.li`
  display: flex;
  align-items: center;
  height: ${(p) => p.theme.spacing.l};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

const ButtonWrapper = styled.div`
  padding: ${(p) => `0 ${p.theme.spacing.s} ${p.theme.spacing.xs} ${p.theme.spacing.s}`};
  margin-left: 0;
  white-space: nowrap;

  button {
    width: 100%;
  }
`;

const HelpText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  white-space: normal;
  width: 160px;
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const LanguageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FlagWrapper = styled.div``;

const LanguageTextWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  text-transform: uppercase;
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.xxs} ${p.theme.spacing.xs} ${p.theme.spacing.xs}`};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const Separator = styled.div`
  border-right: 1px solid ${(p) => p.theme.color.uiLine};
  height: ${(p) => p.theme.spacing.m};
  margin-right: ${(p) => p.theme.spacing.m};
  margin-left: ${(p) => p.theme.spacing.m};
`;

const IconStatusWrapper = styled.div<{ last: boolean }>`
  position: relative;
  height: ${(p) => p.theme.spacing.m};
  cursor: pointer;
  margin-right: ${(p) => (p.last ? p.theme.spacing.xs : "0")};
`;

const Header = styled.section<{ inversed?: boolean }>`
  min-height: ${(p) => p.theme.spacing.xl};
  background: ${(p) => (p.inversed ? p.theme.color.uiMainMenuBackground : p.theme.color.uiBackground02)};
  display: flex;
  padding-right: ${(p) => p.theme.spacing.s};
  margin-bottom: 1px;
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};

  button:first-child {
    margin-left: ${(p) => p.theme.spacing.m};
  }

  ${ActionMenu} button:first-child {
    margin-left: 0;
  }

  .button {
    margin-right: ${(p) => p.theme.spacing.xs};
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }

  &.animate {
    opacity: 0;
    animation: ${OpacityAnimation} 1s ease-in 1.2s;
    animation-fill-mode: forwards;
  }

  ${Title} {
    color: ${(p) => (p.inversed ? p.theme.color.textHighEmphasisInverse : p.theme.color.textHighEmphasis)};
  }

  ${Subtitle} {
    color: ${(p) => (p.inversed ? p.theme.color.textMediumEmphasisInverse : p.theme.color.textMediumEmphasis)};
  }

  ${LanguageTextWrapper} {
    color: ${(p) => (p.inversed ? p.theme.color.textHighEmphasisInverse : p.theme.color.interactive01)};
  }
`;

const IconWrapper = styled.div<{ inversed?: boolean }>`
  svg {
    path {
      fill: ${(p) => (p.inversed ? p.theme.color.textHighEmphasisInverse : p.theme.color.interactive01)};
    }
  }
`;

const ErrorWrapper = styled.div`
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
  svg {
    path {
      fill: ${(p) => p.theme.color.error};
    }
    cursor: pointer;
  }
`;

const SearchWrapper = styled.div<{ length: number }>`
  width: ${(p) => `calc(${p.theme.spacing.xl} * ${p.length})`};
  display: flex;
  justify-content: flex-end;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
  div {
    margin-bottom: 0;
  }
`;

const StatusBtn = styled.span``;

export {
  ActionMenu,
  Header,
  WrapperTabs,
  Title,
  Subtitle,
  ActionText,
  ActionItem,
  ActionMenuTitle,
  ButtonWrapper,
  IconStatusWrapper,
  LanguageWrapper,
  LanguageTextWrapper,
  FlagWrapper,
  Separator,
  IconWrapper,
  ErrorWrapper,
  SearchWrapper,
  WrapperEnd,
  WrapperTitle,
  TabsContent,
  StatusBtn,
  HelpText,
  ModalContent,
};
