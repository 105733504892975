import React from "react";

import { CheckField, SiteFilter, TableCounter, DateFilter } from "@ax/components";
import { IQueryValue } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    totalItems,
    selectAllItems,
    isScrolling,
    filterItems,
    filterValues,
    isSiteItem,
    sortItems,
    sortedListStatus,
  } = props;

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          checked={false}
          disabled={false}
          error={false}
        />
      </S.CheckHeader>
      {!isSiteItem && (
        <S.SiteHeader>
          <SiteFilter filterItems={filterItems} value={filterValues} pointer="sites" center={false} />
        </S.SiteHeader>
      )}
      <S.UrlHeader>Old URL</S.UrlHeader>
      <S.UrlHeader>New URL</S.UrlHeader>
      <DateFilter sortItems={sortItems} sortedState={sortedListStatus} />
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  filterValues: any;
  isSiteItem: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  sortedListStatus: { isAscending: boolean; sortedByDate: boolean };
}

export default TableHeader;
