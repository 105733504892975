import React from "react";

import { Button, CheckField, TableCounter } from "@ax/components";
import { IBulkAction } from "@ax/types";

import * as S from "./style";

const BulkSelectionOptions = (props: IBulkSelectionProps): JSX.Element => {
  const { isScrolling, checkState, actions, selectItems, totalItems, className } = props;

  const filteredActions = actions.filter((action: IBulkAction | undefined | null) => !!action);

  return (
    <S.BulkHeader isScrolling={isScrolling} data-testid="bulk-header-wrapper" className={className}>
      <S.BulkActionsWrapper>
        <S.SelectAllCheckField>
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={selectItems}
            checked={checkState.isAllSelected}
            indeterminate={checkState.indeterminate}
            disabled={false}
            error={false}
          />
        </S.SelectAllCheckField>
        <S.BulkActions>
          {filteredActions.map(
            (item: IBulkAction | undefined | null) =>
              item && (
                <Button key={item.text} type="button" buttonStyle="text" icon={item.icon} onClick={item.action}>
                  {item.text}
                </Button>
              )
          )}
        </S.BulkActions>
        <S.Counter>
          <TableCounter totalItems={totalItems} />
        </S.Counter>
      </S.BulkActionsWrapper>
    </S.BulkHeader>
  );
};

interface IBulkSelectionProps {
  checkState: any;
  actions: (IBulkAction | undefined | null)[];
  selectItems: () => void;
  totalItems: number;
  isScrolling?: boolean;
  className?: string;
}

export default BulkSelectionOptions;
