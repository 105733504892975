import { Dispatch } from "redux";
import {
  SET_SITES,
  SET_RECENT_SITES,
  SET_SITES_BY_LANG,
  SET_CURRENT_SITE_INFO,
  SET_CURRENT_SITE_PAGES,
  SET_FILTER,
  SET_TOTAL_ITEMS,
  SET_SITES_TOTAL_ITEMS,
  SET_CURRENT_SITE_LANGUAGES,
  SET_SAVED_SITE_INFO,
  DEFAULT_PARAMS,
  SET_CURRENT_SITE_ERROR_PAGES,
  SET_CONTENT_FILTERS,
  SET_CONFIG,
  SET_CURRENT_SEARCH,
  SET_THEME_ELEMENTS,
} from "./constants";

import {
  ISetSitesAction,
  ISetRecentSitesAction,
  ISetSitesByLangAction,
  ISetCurrentSiteInfoAction,
  ISetCurrentSitePagesAction,
  ISetFilter,
  ISetTotalItems,
  ISetCurrentSiteLanguagesAction,
  ISetSavedSiteInfoAction,
  ISetCurrentSiteErrorPages,
  ISetContentFilters,
  ISetSitesTotalItems,
  ISetConfig,
  ISetCurrentSearch,
  ISetThemeElements,
} from "./interfaces";

import {
  ISite,
  IGetSitePagesParams,
  ISettingsForm,
  IGetGlobalPagesParams,
  IPage,
  IGetSitesParams,
  ISiteListConfig,
  IQueryValue,
  IThemeElements,
} from "@ax/types";
import { sites, languages, dataPack, social, structuredData, analytics, pages } from "@ax/api";
import { appActions } from "@ax/containers/App";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { navigationActions, menuActions } from "@ax/containers/Navigation";
import { resetPageEditor } from "@ax/containers/PageEditor/actions";
import { analyticsActions } from "@ax/containers/Analytics";
import { dataPacksActions } from "@ax/containers/Settings/DataPacks";
import { socialActions } from "@ax/containers/Settings/Social";
import { integrationsActions } from "@ax/containers/Integrations";
import { getDefaultTheme, getThemeElements, handleRequest, isReqOk, sortBy } from "@ax/helpers";
import { usersActions } from "@ax/containers/Users";

const { setIsLoading, setIsSaving, setLanguage } = appActions;
const { resetDefaultsValues, getDefaults } = navigationActions;
const { resetMenuValues } = menuActions;
const { getAnalytics } = analyticsActions;
const { getIntegrations } = integrationsActions;
const { getUsers, getUserCurrentPermissions, getRoles } = usersActions;

function setSites(sitesList: ISite[]): ISetSitesAction {
  return { type: SET_SITES, payload: { sites: sitesList } };
}

function setRecentSites(recentSites: ISite[]): ISetRecentSitesAction {
  return { type: SET_RECENT_SITES, payload: { recentSites } };
}

function setSitesTotalItems(sitesTotalItems: number): ISetSitesTotalItems {
  return { type: SET_SITES_TOTAL_ITEMS, payload: { sitesTotalItems } };
}

function setSitesByLang(sitesList: ISite[]): ISetSitesByLangAction {
  return { type: SET_SITES_BY_LANG, payload: { sitesByLang: sitesList } };
}

function setCurrentSiteInfo(currentSiteInfo: ISite | null): ISetCurrentSiteInfoAction {
  return { type: SET_CURRENT_SITE_INFO, payload: { currentSiteInfo } };
}

function setCurrentSitePages(currentSitePages: IPage[]): ISetCurrentSitePagesAction {
  return { type: SET_CURRENT_SITE_PAGES, payload: { currentSitePages } };
}

function setFilter(currentFilter: string | null): ISetFilter {
  return { type: SET_FILTER, payload: { currentFilter } };
}

function setTotalItems(totalItems: number): ISetTotalItems {
  return { type: SET_TOTAL_ITEMS, payload: { totalItems } };
}

function setCurrentSiteLanguages(currentSiteLanguages: any[]): ISetCurrentSiteLanguagesAction {
  return { type: SET_CURRENT_SITE_LANGUAGES, payload: { currentSiteLanguages } };
}

function setSavedSiteInfo(savedSiteInfo: ISite): ISetSavedSiteInfoAction {
  return { type: SET_SAVED_SITE_INFO, payload: { savedSiteInfo } };
}

function setCurrentSiteErrorPages(currentSiteErrorPages: number[]): ISetCurrentSiteErrorPages {
  return { type: SET_CURRENT_SITE_ERROR_PAGES, payload: { currentSiteErrorPages } };
}

function setContentFilters(contentFilters: Record<string, IQueryValue[]> | null): ISetContentFilters {
  return { type: SET_CONTENT_FILTERS, payload: { contentFilters } };
}

function setConfig(config: ISiteListConfig): ISetConfig {
  return { type: SET_CONFIG, payload: { config } };
}

function setCurrentSearch(currentSearch: string): ISetCurrentSearch {
  return { type: SET_CURRENT_SEARCH, payload: { currentSearch } };
}

function setThemeElements(themeElements: IThemeElements | null): ISetThemeElements {
  return { type: SET_THEME_ELEMENTS, payload: { themeElements } };
}

// TODO: hay que controlar que cuando da error la API borrar los sites ya guardados y sacar el error (ver los siguientes FIXME)
function getSites(params: IGetSitesParams = { recentSitesNumber: 7 }): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: (data: any) => {
          dispatch(setSites(data.allSites));
          dispatch(setRecentSites(data.recentSites));
          dispatch(setSitesTotalItems(data.totalItems));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };
      const callback = async () => sites.getAllSites(params);

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function getSite(siteID: number): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const responseActions = {
        handleSuccess: async (data: ISite) => {
          await setSiteInfo(data)(dispatch, getState);
          await getDefaults()(dispatch, getState);
          await getIntegrations(data.id)(dispatch);
          dispatch(setCurrentSiteInfo(data));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };
      const callback = async () => sites.getSiteInfo(siteID);

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function getSitesByLang(params: IGetSitesParams): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: (data: any) => {
          dispatch(setSitesByLang(data.allSites));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => sites.getAllSites(params);

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function saveSettings(form: ISettingsForm): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo },
      } = getState();

      const isNewSite = !currentSiteInfo;

      const theme = getDefaultTheme();

      const responseActions = {
        handleSuccess: (response: ISite) => {
          setSiteInfo(response)(dispatch, getState);
          const themeElements = getThemeElements(response.theme) || null;
          dispatch(setThemeElements(themeElements));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () =>
        isNewSite ? sites.createSite({ ...form, theme }) : sites.updateSite(currentSiteInfo.id, form);

      return await handleRequest(callback, responseActions, [appActions.setIsSaving])(dispatch);
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
    dispatch(setIsSaving(false));
    return false;
  };
}

function getGlobalPages(params: IGetGlobalPagesParams, filterQuery: string): (dispatch: any) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: (response: any) => {
          const { items, totalItems } = response;
          dispatch(setCurrentSitePages(items));
          dispatch(setTotalItems(totalItems));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => sites.getGlobalPages(params, filterQuery);

      await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log("error");
      console.log(e); // FIXME: capturar errores
    }
  };
}

function setSiteInfo(currentSiteInfo: ISite): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: { structuredData: currentStructuredData },
        structuredData: { categories: currentCategories },
      } = getState();

      dispatch(setIsLoading(true));

      resetSiteValues(currentSiteInfo.id)(dispatch);
      dispatch(structuredDataActions.resetStructuredData());
      dispatch(setCurrentSiteInfo(currentSiteInfo));
      await getRoles({ siteId: currentSiteInfo.id }, undefined, false)(dispatch);

      const themeElements = getThemeElements(currentSiteInfo.theme) || null;
      dispatch(setThemeElements(themeElements));

      // get site languages
      const response: any = await languages.getSiteLanguages(currentSiteInfo.id);
      if (isReqOk(response.status)) {
        const siteLanguages = response.data.items;
        const defaultLanguage = siteLanguages.find((language: any) => language.isDefault);
        const { locale, id } = defaultLanguage;
        const lang = {
          locale,
          id,
        };

        dispatch(setLanguage(lang));
        dispatch(setCurrentSiteLanguages(siteLanguages));
      } else {
        console.log("Error en sites getSiteLanguages"); // FIXME: capturar errores mejor
      }

      // get site activated dataPacks
      const getActivatedQuery = "?status=activated";
      const dataPackResponse: any = await dataPack.getSiteDataPacks(currentSiteInfo.id, getActivatedQuery);
      if (isReqOk(dataPackResponse.status)) {
        const data = dataPackResponse.data.items;
        const orderedData = data.sort(sortBy("title", false));
        dispatch(dataPacksActions.setActivated(orderedData));
        dispatch(dataPacksActions.getSiteModules());
        dispatch(dataPacksActions.getSiteTemplates());
      } else {
        console.log("Error en sites getSiteDataPacks"); // FIXME: capturar errores mejor
      }

      // get site socials
      const socialResponse: any = await social.getSocial(currentSiteInfo.id);
      if (isReqOk(socialResponse.status)) {
        dispatch(socialActions.setSocial(socialResponse.data));
      } else {
        console.log("Error en sites getSocial"); // FIXME: capturar errores mejor
      }

      // get structuredData
      const structuredDataResponse: any = await structuredData.getData(null, currentSiteInfo.id);
      if (isReqOk(structuredDataResponse.status)) {
        const data = structuredDataResponse.data.items;

        const categories = structuredDataActions.filterStructuredData(data, true, currentSiteInfo.id);

        const structuredDataValues = structuredDataActions.filterStructuredData(data, false, currentSiteInfo.id);
        dispatch(structuredDataActions.setCategories({ global: currentCategories.global, site: categories.site }));

        dispatch(
          structuredDataActions.setStructuredData({ ...currentStructuredData, site: structuredDataValues.site })
        );
      } else {
        console.log("Error en sites getStructuredData"); // TODO: capturar errores mejor
      }

      // get analytics
      const analyticsResponse: any = await analytics.getAnalytics(currentSiteInfo.id);
      if (isReqOk(socialResponse.status)) {
        dispatch(analyticsActions.setAnalytics(analyticsResponse.data));
      } else {
        console.log("Error en sites getAnalytics"); // FIXME: capturar errores mejor
      }

      getUserCurrentPermissions()(dispatch, getState);
    } catch (e) {
      console.log(e); // FIXME: capturar errores
    }
  };
}

function getFilteredContent(filter: any): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const { value, fromPage } = filter;
      dispatch(setFilter(value));

      const {
        sites: { currentSiteInfo },
        app: { token },
      } = getState();

      if (value === "unique-pages" || fromPage) {
        const params = {
          siteID: currentSiteInfo.id,
          ...DEFAULT_PARAMS,
        };
        getSitePages(params, value)(dispatch);
      } else {
        const params = {
          dataID: filter,
          token,
          ...DEFAULT_PARAMS,
        };
        dispatch(structuredDataActions.setSelectedStructuredData(value, "site"));
        structuredDataActions.getStructuredDataContents(params, currentSiteInfo.id)(dispatch, getState);
      }
    } catch (e) {
      console.log(e); // FIXME: capturar errores mejor
    }
  };
}

function getSitePages(
  params: IGetSitePagesParams,
  structuredData?: string | null,
  filterQuery?: string
): (dispatch: any) => Promise<void> {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response: any = structuredData
        ? await sites.getStructuredSitePages(params, structuredData, filterQuery)
        : await sites.getSitePages(params, filterQuery);

      if (isReqOk(response.status)) {
        const { items, totalItems } = response.data;
        dispatch(setCurrentSitePages(items));
        dispatch(setTotalItems(totalItems));
      } else {
        console.log("Error en getSitePages"); // FIXME: capturar errores mejor
      }
      dispatch(setIsLoading(false));
    } catch (e) {
      console.log(e); // FIXME: capturar errores mejor
    }
  };
}

function getSiteLanguages(siteID: number): (dispatch: any) => Promise<void> {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response: any = await languages.getSiteLanguages(siteID);
      dispatch(setIsLoading(false));
      if (isReqOk(response.status)) {
        const siteLanguages = response.data.items;

        const defaultLanguage = siteLanguages.find((language: any) => language.isDefault);
        const { locale, id } = defaultLanguage;
        const lang = {
          locale,
          id,
        };

        dispatch(setLanguage(lang));
        dispatch(setCurrentSiteLanguages(siteLanguages));
      } else {
        console.log("Error en getSiteLanguages"); // FIXME: capturar errores mejor
      }
    } catch (e) {
      console.log(e); // FIXME: capturar errores mejor
    }
  };
}

function deleteSite(siteID: number, params?: IGetSitesParams): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: () => {
          getSites(params)(dispatch);
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };
      const callback = async () => sites.deleteSite(siteID);

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

function publishSite(siteID: number, params?: IGetSitesParams): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const response = await sites.publishSite(siteID);
      if (isReqOk(response.status)) {
        getSites(params)(dispatch);
      }
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

function publishSitesBulk(ids: number[], params?: IGetSitesParams): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: () => getSites(params)(dispatch),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };
      const callback = async () => sites.publishSiteBulk(ids);

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

function unpublishSite(siteID: number, params?: IGetSitesParams): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const response = await sites.unpublishSite(siteID);
      if (isReqOk(response.status)) {
        getSites(params)(dispatch);
      }
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

function unpublishSitesBulk(ids: number[], params?: IGetSitesParams): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: () => getSites(params)(dispatch),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };
      const callback = async () => sites.unpublishSiteBulk(ids);

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

function resetSiteValues(siteID: number): (dispatch: Dispatch) => void {
  return async (dispatch) => {
    dispatch(setFilter("unique-pages"));
    resetDefaultsValues()(dispatch);
    resetPageEditor()(dispatch);
    resetMenuValues(dispatch);
    dispatch(setCurrentSitePages([]));
    dispatch(setTotalItems(0));
    getAnalytics(siteID)(dispatch);
    dispatch(setCurrentSearch(""));
    dispatch(setThemeElements(null));
  };
}

function saveCurrentSiteInfo(): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo },
      } = getState();

      dispatch(setSavedSiteInfo(currentSiteInfo));
      dispatch(setCurrentSiteInfo(null));
    } catch (e) {
      console.log(e);
    }
  };
}

function importPageFromGlobal(pageID: number | number[]): (dispatch: any, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo, currentFilter },
      } = getState();

      const responseActions = {
        handleSuccess: () => {
          const params = {
            siteID: currentSiteInfo.id,
            ...DEFAULT_PARAMS,
          };
          const filter = currentFilter === "unique-pages" ? null : currentFilter;
          getSitePages(params, filter)(dispatch);
        },
        handleError: (response: any) => {
          const {
            data: { message },
          } = response;
          const isMultiple = Array.isArray(message) && message.length > 1;
          const msg = isMultiple ? `The import action failed due to ${message.length} errors.` : undefined;
          appActions.handleError(response, isMultiple, msg)(dispatch);
        },
      };

      const callback = async () =>
        Array.isArray(pageID)
          ? sites.importPageBulk(currentSiteInfo.id, pageID)
          : sites.importPage(currentSiteInfo.id, pageID);

      return await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function removeUsersBulk(siteId: number, users: number[]): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const response = await sites.removeUsersBulk(siteId, users);
      if (isReqOk(response.status)) {
        const params = { filterQuery: "?order=dateCreated" };
        getUsers(params, siteId)(dispatch);
      }
    } catch (e) {
      console.log(e); // TODO: capturar error bien
    }
  };
}

function removePageFromSite(
  pageID: number | number[],
  refresh = true
): (dispatch: any, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo },
        structuredData: { currentStructuredData },
      } = getState();

      const responseActions = {
        handleSuccess: () => {
          const params = {
            siteID: currentSiteInfo.id,
            ...DEFAULT_PARAMS,
          };
          const filter = currentStructuredData ? currentStructuredData.id : null;
          if (refresh) {
            getSitePages(params, filter)(dispatch);
          }
        },
        handleError: (response: any) => {
          const {
            data: { message },
          } = response;
          const isMultiple = Array.isArray(message) && message.length > 1;
          const msg = isMultiple ? `The delete action failed due to ${message.length} errors.` : undefined;
          appActions.handleError(response, isMultiple, msg)(dispatch);
        },
      };

      const callback = async () =>
        Array.isArray(pageID)
          ? sites.removePageBulk(currentSiteInfo.id, pageID)
          : sites.removePage(currentSiteInfo.id, pageID);

      return await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function deleteAndRemoveFromSiteBulk(
  pageIds: number[],
  globalPageIds: number[]
): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo },
      } = getState();

      let responseErrorPages: any = { data: { code: null, message: null } };

      const getMessageErrors = () => {
        const {
          data: { message },
        } = responseErrorPages;

        const isMultiple = Array.isArray(message) && message.length > 1;
        const msg = isMultiple ? `The delete action failed due to ${message.length} errors.` : undefined;
        appActions.handleError(responseErrorPages, isMultiple, msg)(dispatch);
      };

      const responsePageActions = {
        handleSuccess: () => true,
        handleError: (response: any) => {
          responseErrorPages = response;
          getMessageErrors();
        },
      };

      const responseGlobalPageActions = {
        handleSuccess: () => {
          if (responseErrorPages.data.message?.length > 0) {
            getMessageErrors();
          }
        },
        handleError: (response: any) => {
          const errorMessages = responseErrorPages.data?.message ? responseErrorPages.data.message : [];
          responseErrorPages = {
            ...responseErrorPages,
            data: {
              code: responseErrorPages.data?.code || response.data?.code,
              message: response.data ? [...errorMessages, ...response.data.message] : errorMessages,
            },
          };

          getMessageErrors();
        },
      };

      const pageNoGlobalIds = pageIds.filter((elemento) => !globalPageIds.includes(elemento));

      const callbackPages = async () => pages.bulkDelete(pageNoGlobalIds);
      const callbackGlobalPages = async () => sites.removePageBulk(currentSiteInfo.id, globalPageIds);

      let errors = 0;

      if (pageNoGlobalIds.length > 0) {
        const result = await handleRequest(callbackPages, responsePageActions, [])(dispatch);
        if (!result) {
          errors = errors + 1;
        }
      }

      if (globalPageIds.length > 0) {
        const result = await handleRequest(callbackGlobalPages, responseGlobalPageActions, [])(dispatch);
        if (!result) {
          errors = errors + 1;
        }
      }

      return errors > 0 ? false : true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function resetCurrentSiteErrorPages(): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      dispatch(setCurrentSiteErrorPages([]));
    } catch (e) {
      console.log(e);
    }
  };
}

function setListConfig(config: ISiteListConfig): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      localStorage.setItem("sitesConfig", JSON.stringify(config));
      dispatch(setConfig(config));
    } catch (e) {
      console.log(e);
    }
  };
}

function updateCurrentSearch(query: string): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      dispatch(setCurrentSearch(query));
    } catch (e) {
      console.log(e);
    }
  };
}

export {
  setCurrentSiteInfo,
  setCurrentSitePages,
  setFilter,
  setTotalItems,
  setCurrentSiteLanguages,
  getSites,
  getSitesByLang,
  setSiteInfo,
  getFilteredContent,
  getSitePages,
  getSiteLanguages,
  deleteSite,
  publishSite,
  publishSitesBulk,
  unpublishSite,
  unpublishSitesBulk,
  saveSettings,
  getGlobalPages,
  setSavedSiteInfo,
  saveCurrentSiteInfo,
  importPageFromGlobal,
  removePageFromSite,
  removeUsersBulk,
  setCurrentSiteErrorPages,
  resetCurrentSiteErrorPages,
  setContentFilters,
  deleteAndRemoveFromSiteBulk,
  setListConfig,
  getSite,
  updateCurrentSearch,
};
